/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable import/prefer-default-export */

interface Items {
  key: string;
  id: string;
  link: string;
  label: string;
  isCurrentPage?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  style?: React.CSSProperties;
}

export const generateBreadCrumbRoutes = (
  pathname: string,
  labels?: string[],
  navegate?: any,
  setModalGoBackVisible?: React.Dispatch<React.SetStateAction<boolean>>,
  setPendingNavigation?: React.Dispatch<React.SetStateAction<string | null>>,
  setEnableEditForm?: React.Dispatch<React.SetStateAction<boolean>>,
  formWithValues?: boolean,
): Items[] => {
  const pathSegments = pathname.split('/').filter(segment => segment);
  const items = pathSegments.map((segment, index) => {
    const link = `/${pathSegments.slice(0, index + 1).join('/')}`;

    return {
      key: (index + 2).toString(),
      id: `item${index + 2}`,
      link,
      label: labels && labels[index + 1] ? labels[index + 1] : segment,
      isCurrentPage: index === pathSegments.length - 1,
      onClick: (e: any) => {
        e.preventDefault();

        if ((segment.toLowerCase() === 'inicio' || segment.toLowerCase() === 'reglas-automaticas') && formWithValues) {
          setPendingNavigation?.(link);
          setModalGoBackVisible?.(true);
        } else if (pathname === '/reglas-automaticas/resultado') {
          navegate(link);
        } else if (link) {
          setEnableEditForm(true);
          navegate(link);
        }
      },
    };
  });

  return [
    {
      key: '0',
      id: 'item0',
      link: '/',
      label: labels && labels[0] ? labels[0] : 'inicio',
      isCurrentPage: pathSegments.length === 0,
      onClick: e => {
        e.preventDefault();
        if (formWithValues) {
          setModalGoBackVisible?.(true);
          setPendingNavigation?.('/');
        } else {
          navegate('/');
        }
      },
    },
    {
      key: '1',
      id: 'item1',
      link: '/',
      label: 'Inversiones',
      isCurrentPage: true,
      style: { color: '#c0ace4', fontWeight: 600 },
    },
    ...items,
  ];
};
