export const bankOptions = [
  { label: 'CitiBank', value: '016', name: 'CitiBank' },
  { label: 'Ciudad', value: '029', name: 'Ciudad' },
  { label: 'Galicia', value: '007', name: 'Galicia' },
];

export const AccountFromOptions = [
  { label: 'CC | ARS | 30-14234657-1 ', value: '30142346571', idBank: '016', idBrokerage: ['788493943930230303'] },
  { label: 'CC | ARS | 30-24234657-4 ', value: '30242346574', idBank: '016', idBrokerage: ['748493943930230301'] },
  { label: 'CC | ARS | 30-34234657-8 ', value: '30342346578', idBank: '016', idBrokerage: ['788493943930230303'] },
  {
    label: 'CC | ARS | 40-74234657-1 ',
    value: '40742346571',
    idBank: '007',
    idBrokerage: ['858493943930230301', '858493943930230303', '858493943930230307'],
  },
  { label: 'CC | ARS | 40-74234657-4 ', value: '40742346574', idBank: '007', idBrokerage: ['658493943930230303', '658493943930230304'] },
  { label: 'CC | ARS | 50-74234657-7 ', value: '50742346577', idBank: '029', idBrokerage: [] },
];

export const AccountBrokerOptions = [
  { label: '748493943930230301', value: '748493943930230301', idAccount: '30242346574', idBank: '016' },
  { label: '748493943930230302', value: '748493943930230302', idAccount: '30242346574', idBank: '016' },
  { label: '788493943930230303', value: '788493943930230303', idAccount: '30142346571', idBank: '016' },
  { label: '658493943930230303', value: '658493943930230303', idAccount: '40742346574', idBank: '007' },
  { label: '658493943930230304', value: '658493943930230304', idAccount: '40742346574', idBank: '007' },
  { label: '858493943930230301', value: '858493943930230301', idAccount: '40742346571', idBank: '007' },
  { label: '858493943930230303', value: '858493943930230303', idAccount: '40742346571', idBank: '007' },
  { label: '858493943930230307', value: '858493943930230307', idAccount: '40742346571', idBank: '007' },
];

export const AccountFundOptions = [
  {
    label: 'Alpha Pesos - Clase A',
    value: '101',
    idBank: '007',
    fundName: 'Alpha Pesos - Clase A',
    fundType: 'Money Market',
    period: 'Inmediato',
    operationTime: '10:00 a 18:00 horas',
    timeStart: '10:00',
    timeEnd: '18:00',
  },
  {
    label: 'Fima Pesos - Clase B',
    value: '102',
    idBank: '007',
    fundName: 'Fima Pesos - Clase B',
    fundType: 'Money Market',
    period: 'Inmediato',
    operationTime: '10:00 a 17:00 horas',
    timeStart: '10:00',
    timeEnd: '17:00',
  },
  {
    label: 'Fima Premium - Clase A',
    value: '103',
    idBank: '007',
    fundName: 'Fima Premium - Clase A',
    fundType: 'Money Market',
    period: 'Inmediato',
    operationTime: '10:00 a 16:00 horas',
    timeStart: '10:00',
    timeEnd: '16:00',
  },
  {
    label: 'Fima Platinum - Clase A',
    value: '104',
    idBank: '016',
    fundName: 'Fima Platinum - Clase A',
    fundType: 'Money Market',
    period: 'Inmediato',
    operationTime: '10:00 a 16:00 horas',
    timeStart: '10:00',
    timeEnd: '16:00',
  },
  {
    label: 'Gem Premium - Clase A',
    value: '105',
    idBank: '016',
    fundName: 'Gem Premium - Clase A',
    fundType: 'Money Market',
    period: 'Inmediato',
    operationTime: '09:00 a 17:00 horas',
    timeStart: '09:00',
    timeEnd: '17:00',
  },
  {
    label: 'Nacional Platinum - Clase A',
    value: '106',
    idBank: '029',
    fundName: 'Nacional Platinum - Clase A',
    fundType: 'Money Market',
    period: 'Inmediato',
    operationTime: '10:00 a 16:00 horas',
    timeStart: '10:00',
    timeEnd: '16:00',
  },
  {
    label: 'ZArg Inver - Clase B',
    value: '107',
    idBank: '029',
    fundName: 'ZArg Inver - Clase B',
    fundType: 'Money Market',
    period: 'Inmediato',
    operationTime: '10:00 a 18:00 horas',
    timeStart: '10:00',
    timeEnd: '18:00',
  },
];

export const scheduleOptions = [
  { label: '00:00 horas', value: '00:00' },
  { label: '01:00 horas', value: '01:00' },
  { label: '02:00 horas', value: '02:00' },
  { label: '03:00 horas', value: '03:00' },
  { label: '04:00 horas', value: '04:00' },
  { label: '05:00 horas', value: '05:00' },
  { label: '06:00 horas', value: '06:00' },
  { label: '07:00 horas', value: '07:00' },
  { label: '08:00 horas', value: '08:00' },
  { label: '09:00 horas', value: '09:00' },
  { label: '10:00 horas', value: '10:00' },
  { label: '11:00 horas', value: '11:00' },
  { label: '12:00 horas', value: '12:00' },
  { label: '13:00 horas', value: '13:00' },
  { label: '14:00 horas', value: '14:00' },
  { label: '15:00 horas', value: '15:00' },
  { label: '16:00 horas', value: '16:00' },
  { label: '17:00 horas', value: '17:00' },
  { label: '18:00 horas', value: '18:00' },
  { label: '19:00 horas', value: '19:00' },
  { label: '20:00 horas', value: '20:00' },
  { label: '21:00 horas', value: '21:00' },
  { label: '22:00 horas', value: '22:00' },
  { label: '23:00 horas', value: '23:00' },
];

export const frequencyOptions = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'];
