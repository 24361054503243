/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { Badge, Box, Button, Checkbox, HStack, Image, Text, Tooltip, useToast, VStack } from '@chakra-ui/react';
import { FullscreenLoading } from '@components/FullscreenLoading/FullscreenLoading.component';
import { StateModal } from '@components/Modal/StateModal.component';
import { IRuleCard } from '@interfaces';
import { deleteRule } from '@services/api';
import {
  formatAccountType,
  formatBankCode,
  formatMoney,
  formatRemainingAuthorizations,
  formatRuleHour,
  formatSchedule,
  getBadgeStatus,
} from '@utils';
import { themeChakra } from '@wow/themes';
import { ToastComponent } from '@wow/toast';
import { useState } from 'react';

import bankDefaultIcon from '../../assets/avatarBanco.svg';
import customText from '../../lang/es.json';

export function RuleCard({ type = 'rule', item, isChecked, onCheckboxChange, fetchRules, itemId }: IRuleCard) {
  const [isUpdatingRule, setIsUpdatingRule] = useState(false);
  const [failureCount, setFailureCount] = useState(0);
  const [stateRuleModal, setStateRuleModal] = useState(false);

  const badgeStatus = getBadgeStatus(item);

  const toast = useToast({
    containerStyle: {
      marginLeft: '60px',
    },
  });

  const handlePauseClick = async id => {
    setStateRuleModal(false);
    setIsUpdatingRule(true);
    try {
      const response = await deleteRule(id);

      if (response.status === 200 || response.status === 204) {
        showStatusToast('success', customText.rulesCardContainer.statusTooltip.successDescription, 0);
        fetchRules({ pages: 1, limit: 15, type: 'all' });
        setFailureCount(0);
      }
    } catch (error) {
      setFailureCount(prev => {
        const updatedFailureCount = prev + 1;
        showStatusToast(
          'error',
          updatedFailureCount < 4
            ? customText.rulesCardContainer.statusTooltip.errorDescriptionOne
            : customText.rulesCardContainer.statusTooltip.errorDescriptionTwo,
          updatedFailureCount,
        );
        return updatedFailureCount;
      });
    } finally {
      setIsUpdatingRule(false);
    }
  };

  const showStatusToast = (status, description, updatedFailureCount) => {
    const toastId = toast({
      duration: status === 'success' ? 4000 : 10000,
      position: 'bottom-left',
      status,
      description,
      isClosable: true,
      render: (props: any) => (
        <ToastComponent
          {...props}
          isCtaLabel={status === 'error' && updatedFailureCount < 4}
          ctaLabel={customText.rulesCardContainer.statusTooltip.retryButton}
          ctaOnClick={() => {
            toast.close(toastId);
            handlePauseClick(item?.payload.id);
          }}
        />
      ),
    });
  };

  return (
    <Box
      borderRadius='12px'
      border='1px solid #BEC6DC'
      mt='24px'
      overflow='hidden'
      fontFamily={themeChakra.textStyles.titleMdBold.fontFamily}
      id={itemId}
    >
      <header
        style={{
          background: 'rgba(0, 0, 0, 0.04)',
          padding: '8px 24px',
          fontWeight: '700',
          borderBottom: '1px solid #B2B7C4',
          display: 'flex',
          justifyContent: 'space-between',
          height: '48px',
          alignItems: 'center',
        }}
      >
        {customText.ruleCard.title}
        {(type === 'rule' || type === 'noAuth') && badgeStatus !== 'Creada' && (
          <Tooltip
            hasArrow
            label={
              type === 'noAuth' && badgeStatus === 'Pausada'
                ? customText.ruleCard.tooltipNoAuthActive
                : type === 'noAuth' && badgeStatus === 'Activa'
                ? customText.ruleCard.tooltipNoAuthPause
                : customText.ruleCard.tooltipActiveAction
            }
            bg='gray.300'
            placement='right'
            isDisabled={type !== 'noAuth' && badgeStatus === 'Activa'}
          >
            <Button
              variant='link'
              fontSize='12px'
              color='#2172DC'
              display='flex'
              alignItems='center'
              textDecoration='underline'
              _hover={{ textDecoration: 'underline' }}
              isDisabled={badgeStatus === 'Pausada' || type === 'noAuth'}
              onClick={() => setStateRuleModal(true)}
              id={`action-${itemId}`}
              padding='5px'
            >
              {badgeStatus === 'Activa' && customText.ruleCard.pauseRule}
              {badgeStatus === 'Pausada' && customText.ruleCard.activeRule}
            </Button>
          </Tooltip>
        )}
      </header>

      <HStack padding='16px' color='#3B414E' gap='36px' w='100%'>
        <VStack minWidth={type === 'rule' || type === 'noAuth' ? '49px' : ''}>
          {type === 'authorization' && <Checkbox isChecked={isChecked} onChange={() => onCheckboxChange(item?.payload?.id)} />}
          {(type === 'rule' || type === 'noAuth') && (
            <Tooltip
              hasArrow
              label={formatRemainingAuthorizations(item)}
              placement='auto'
              bg='gray.300'
              isDisabled={badgeStatus !== 'Creada'}
            >
              <Badge variant={badgeStatus === 'Activa' ? 'success-light' : badgeStatus === 'Pausada' ? 'neutral-light' : 'warning-light'}>
                {badgeStatus}
              </Badge>
            </Tooltip>
          )}
        </VStack>

        <HStack gap='16px' w='100%' marginInlineStart='3px !important'>
          <HStack w='100%' justifyContent='start' alignSelf='center' marginInlineStart='0px !important'>
            <VStack w='max-content'>
              <Image
                width='40px'
                height='40px'
                borderRadius='full'
                src={
                  item?.payload?.bank.id === 0
                    ? bankDefaultIcon
                    : `${process.env.URL_BANK_LOGOS}logo${formatBankCode(item?.payload?.bank.id)}.png`
                }
                alt='bank-logo'
                mr='15px'
                border='1px solid #565E71'
              />
            </VStack>
            <VStack alignItems='start' w='100%' alignSelf='center'>
              <Text fontWeight='700'>{customText.ruleCard.subTitleAccount}</Text>
              <Text fontSize='12px' fontWeight='600' textTransform='uppercase' letterSpacing='1.2px' marginTop='0 !important'>
                {item?.payload?.bank?.name}
              </Text>
              <Text fontSize='14px' marginTop='0 !important'>
                {formatAccountType(item?.payload?.account?.type)} | {item?.payload?.account?.currencyCode} | {item?.payload?.account?.id}
              </Text>
            </VStack>
          </HStack>

          <VStack alignItems='start' w='100%' alignSelf='center' marginInlineStart='0px !important'>
            <Text fontWeight='700'>{customText.ruleCard.subTitleFund}</Text>
            <Text fontSize='14px' marginTop='0 !important'>
              {item?.payload?.fund.name} {item?.payload?.fund?.fundClass && `- Clase ${item?.payload?.fund?.fundClass} `}{' '}
              {item?.payload?.fund?.type}
            </Text>
          </VStack>

          <VStack alignItems='start' w='100%' alignSelf='center' paddingLeft='12px' marginInlineStart='0px !important'>
            <Text fontWeight='700'>{customText.ruleCard.subTitleAmount}</Text>
            <Text fontSize='14px' marginTop='0 !important'>
              {`${customText.ruleCard.labelFundTextOne} ${formatMoney({ value: item?.payload?.accountMinimumBalance })} ${
                customText.ruleCard.labelFundTextTwo
              }`}
            </Text>
          </VStack>

          <VStack alignItems='start' w='100%' alignSelf='center' paddingLeft='12px' marginInlineStart='0px !important'>
            <Text fontWeight='700'>{customText.ruleCard.subTitleScheduler}</Text>
            <Text fontSize='14px' marginTop='0 !important'>
              {`${formatSchedule(item?.daysOfWeek)} a las ${formatRuleHour(item?.timeOfDay)}`}
            </Text>
          </VStack>
        </HStack>
      </HStack>

      {type === 'authorization' && (
        <footer
          style={{
            color: '#2F3034',
            padding: '8px 24px',
            fontSize: '14px',
            borderTop: '1px solid #B2B7C4',
            display: 'flex',
            alignItems: 'center',
            height: '36px',
          }}
        >
          {formatRemainingAuthorizations(item)}
        </footer>
      )}
      {isUpdatingRule && (
        <FullscreenLoading
          loading={isUpdatingRule}
          loadingTitle1={customText.fullLoadingScreen.title}
          loadingSubtitle1={customText.fullLoadingScreen.description}
        />
      )}

      <StateModal
        headerState='warning'
        headerText={customText.rulesCardContainer.modalRule.titlePause}
        cancelButtonText={customText.rulesCardContainer.modalRule.cancelButton}
        continueButtonText={customText.rulesCardContainer.modalRule.continueButtonPause}
        isCancelButtonVisible
        isContinueButtonVisible
        isOpen={stateRuleModal}
        onContinue={() => handlePauseClick(item?.payload.id)}
        onClose={() => setStateRuleModal(false)}
        isCentered
        isCloseButtonVisible
        data-testid='modal-home-info'
      >
        <Text whiteSpace='break-spaces' textStyle='bodyMd' textAlign='center'>
          {customText.rulesCardContainer.modalRule.descriptionPauseOne}
        </Text>
        <Text whiteSpace='break-spaces' textStyle='bodyMd' fontSize='12px' textAlign='center' color='#037193' sx={{ textWrap: 'auto' }}>
          {customText.rulesCardContainer.modalRule.descriptionPauseTwo}
        </Text>
      </StateModal>
    </Box>
  );
}
