/* eslint-disable import/no-unresolved */
import { ErrorPermissionContent } from '@components';
import { hasSomePermission } from '@utils';
import { CSSProperties, ReactElement } from 'react';

interface WithPermissionsProps {
  children: ReactElement;
  permission: string[];
  showErrorPage?: boolean;
  titleStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  customCheck?: () => boolean;
}

export function WithPermissions({
  children,
  permission,
  showErrorPage = false,
  titleStyle,
  bodyStyle,
  customCheck = () => true,
}: WithPermissionsProps): ReactElement | null {
  if (hasSomePermission(permission) && customCheck()) {
    return children;
  }

  return showErrorPage ? <ErrorPermissionContent redirectTo='/' titleStyle={titleStyle} bodyStyle={bodyStyle} /> : null;
}

export default WithPermissions;
