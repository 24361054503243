/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react';
import { BottomNav, Breadcrumb, CloseModal, StateModal, SubscriptionRuleForm } from '@components';
import { generateBreadCrumbRoutes, validateRisk } from '@utils';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { useContext, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';

import customText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';

function SubscriptionRuleStepOnePage() {
  const { ruleBankData, isFormWithValues, isValidFormData, resetSubscriptionRuleData, setEnableEditForm, profileRiskData } = useContext(
    AppContext,
  ) as any;
  const navigate = useNavigate();
  const location = useLocation();
  const [modalLevelRiskVisible, setModalLevelRiskVisible] = useState(false);
  const [modalGoBackVisible, setModalGoBackVisible] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const [banksWithAuth, setBanksWithAuth] = useState(false);

  const [showAlertForBanks, setShowAlertForBanks] = useState(true);

  const breadcrumbLabels = ['Inicio', 'Reglas automáticas', 'Crear regla'];
  useEffect(() => {
    if (ruleBankData && Array.isArray(ruleBankData)) {
      setBanksWithAuth(ruleBankData.some(bank => bank.enabledByBank === true));
      setShowAlertForBanks(false);
    } else {
      setBanksWithAuth(false);
      setShowAlertForBanks(false);
    }
  }, [ruleBankData]);

  const handleContinueGoBack = () => {
    resetSubscriptionRuleData();
    if (pendingNavigation) {
      navigate(pendingNavigation);
      setModalGoBackVisible(false);
      setPendingNavigation(null);
    } else {
      navigate('/reglas-automaticas');
    }
  };

  const handleContinueToSummary = () => {
    navigate('/reglas-automaticas/crear-regla/resumen');
  };

  const handleValidateCurrentRule = () => {
    if (validateRisk(profileRiskData?.investorProfile?.toUpperCase(), profileRiskData?.fundRiskProfile?.toUpperCase())) {
      setModalLevelRiskVisible(true);
    } else {
      handleContinueToSummary();
    }
  };

  const leftContent = (
    <Button
      size='md'
      variant='link'
      color='#2172DC'
      textDecoration='underline'
      fontWeight={700}
      textUnderlineOffset='3px'
      onClick={() => (isFormWithValues ? setModalGoBackVisible(true) : handleContinueGoBack())}
      leftIcon={<Icon as={ChevronLeft} />}
    >
      {customText.steps.createRule.bottomNav.goBackButton}
    </Button>
  );

  const rightContent = (
    <Box display='flex' justifyContent='center' alignItems='center' gap='20px'>
      <Box fontFamily='Inter' fontSize='14px'>
        {customText.steps.createRule.bottomNav.StepText}
      </Box>
      <Button size='md' isDisabled={!isValidFormData} rightIcon={<Icon as={ChevronRight} />} onClick={handleValidateCurrentRule}>
        {customText.steps.createRule.bottomNav.nextStepButton}
      </Button>
    </Box>
  );

  return (
    <Box ml={30} mr={30} mt={1} mb={90}>
      <Breadcrumb
        itemsProps={generateBreadCrumbRoutes(
          location.pathname,
          breadcrumbLabels,
          navigate,
          setModalGoBackVisible,
          setPendingNavigation,
          setEnableEditForm,
          isFormWithValues,
        )}
      />
      <Box>
        <HStack style={{ justifyContent: 'space-between' }}>
          <Text data-testid='title-subscription-automation-rules-page' color='#292D36' as='h1' textStyle='h1' fontSize='32px' mb='8px'>
            {customText.steps.createRule.title}
          </Text>
        </HStack>
        <Text data-testid='description-subscription-automation-rules-page' textStyle='bodyMd' color='#292D36'>
          {customText.steps.createRule.description}
        </Text>
      </Box>

      {!showAlertForBanks && ruleBankData && !banksWithAuth && (
        <AlertComponent mt='18px' id='alert-component' variant='information'>
          <AlertTitle>
            {Array.isArray(ruleBankData) && ruleBankData.length === 0
              ? customText.steps.createRule.alert.noEnabledBanks.title
              : !banksWithAuth && customText.steps.createRule.alert.noAuthorizedBanks.title}
          </AlertTitle>
          <AlertDescription>
            {Array.isArray(ruleBankData) && ruleBankData.length === 0 ? (
              <>
                {customText.steps.createRule.alert.noEnabledBanks.description}
                <b>Invertir</b>
                {customText.steps.createRule.alert.noEnabledBanks.descriptionTwo}
                <b>Administración</b>
                {customText.steps.createRule.alert.noEnabledBanks.descriptionThree}
              </>
            ) : (
              !banksWithAuth && customText.steps.createRule.alert.noAuthorizedBanks.description
            )}
          </AlertDescription>
        </AlertComponent>
      )}

      <SubscriptionRuleForm isDisabledForm={!ruleBankData || (ruleBankData && !banksWithAuth)} />

      <BottomNav leftContent={leftContent} rightContent={rightContent} isExpanded />
      <StateModal
        headerState='warning'
        headerText={customText.steps.createRule.modal.fci.title}
        cancelButtonText={customText.steps.createRule.modal.fci.cancelButton}
        continueButtonText={customText.steps.createRule.modal.fci.acceptButton}
        isCancelButtonVisible
        isContinueButtonVisible
        isOpen={modalLevelRiskVisible}
        onContinue={handleContinueToSummary}
        onClose={() => setModalLevelRiskVisible(false)}
        isCentered
        isCloseButtonVisible
        size='md'
      >
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd'>
          {customText.steps.createRule.modal.fci.descMain}
        </Text>
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' fontSize='12px' color='#037193' sx={{ textWrap: 'auto' }}>
          {customText.steps.createRule.modal.fci.descSecond}
        </Text>
      </StateModal>
      <CloseModal isOpen={modalGoBackVisible} onContinue={handleContinueGoBack} onClose={() => setModalGoBackVisible(false)} />
    </Box>
  );
}

export default SubscriptionRuleStepOnePage;
