/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { Box, HStack, Text } from '@chakra-ui/react';
import { Breadcrumb, StateModal, SubscriptionRulesManagement } from '@components';
import { SubscriptionRulesManagementWithWizard } from '@components/RulesTabs/SubscriptionRulesManagementWithWizard.component';
import { generateBreadCrumbRoutes } from '@utils';
import { useContext, useState } from 'react';
import { HelpCircle } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';

import customText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';

export function Home() {
  const breadcrumbCustomLabels = ['Inicio', 'Reglas automáticas'];
  const navigate = useNavigate();
  const location = useLocation();

  const [modalInfoVisible, setModalInfoVisible] = useState(false);

  const { wizardCompleted } = useContext(AppContext) as any;

  return (
    <Box ml={30} mr={30} mt={1}>
      <Breadcrumb itemsProps={generateBreadCrumbRoutes(location.pathname, breadcrumbCustomLabels, navigate)} />
      <Box>
        <HStack>
          <Text data-testid='title-automation-rules-page' color='#292D36' as='h1' textStyle='h1' mb='8px' id='title-automation-rules-page'>
            {customText.home.header.title}
          </Text>
          <button style={{ marginLeft: '15px', marginTop: '-10px' }} onClick={() => setModalInfoVisible(true)}>
            <HelpCircle width={24} height={24} color='#2172DC' />
          </button>
        </HStack>
        <Text data-testid='description-automation-rules-page' textStyle='bodyMd' color='#292D36'>
          {customText.home.header.description}
        </Text>
        {wizardCompleted ? <SubscriptionRulesManagement /> : <SubscriptionRulesManagementWithWizard />}
      </Box>

      <StateModal
        headerState='info'
        headerText={customText.home.modal.info.title}
        cancelButtonText=''
        continueButtonText={customText.home.modal.info.button}
        isCancelButtonVisible={false}
        isContinueButtonVisible
        isOpen={modalInfoVisible}
        onContinue={() => setModalInfoVisible(false)}
        onClose={() => setModalInfoVisible(false)}
        isCentered
        isCloseButtonVisible
        size='3xl'
        w='576px'
        h='528px'
        data-testid='modal-home-info'
      >
        <Text whiteSpace='break-spaces' textStyle='bodyMd' textAlign='start' sx={{ textWrap: 'auto' }}>
          {customText.home.modal.info.descMain}
        </Text>
        <Text whiteSpace='break-spaces' textStyle='bodyMd' fontSize='14px' textAlign='start'>
          {customText.home.modal.info.descSecond}
        </Text>

        <Box>
          <Text whiteSpace='break-spaces' textStyle='bodyMd' fontSize='14px' textAlign='start'>
            <strong> {customText.home.modal.info.itemTitleCreated}</strong>
            {customText.home.modal.info.itemDescCreated}
          </Text>
          <Text whiteSpace='break-spaces' textStyle='bodyMd' fontSize='14px' textAlign='start'>
            <strong> {customText.home.modal.info.itemTitleActive}</strong>
            {customText.home.modal.info.itemDescActive}
          </Text>
          <Text whiteSpace='break-spaces' textStyle='bodyMd' fontSize='14px' textAlign='start'>
            <strong> {customText.home.modal.info.itemTitlePaused}</strong>
            {customText.home.modal.info.itemDescPaused}
          </Text>
        </Box>
      </StateModal>
    </Box>
  );
}

export default Home;
