/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { IErrorState, IFetchingState, IFetchRulesRequest, IRulesData } from '@interfaces';
import { getRules } from '@services/api';
import { useState } from 'react';

export function useFetchRules(
  setRulesData: React.Dispatch<React.SetStateAction<IRulesData>>,
  setShowErrorScreen: React.Dispatch<React.SetStateAction<IErrorState>>,
) {
  const [isFetchingRules, setIsFetchingRules] = useState<IFetchingState>({ rules: false, pending: false });

  const fetchRulesData = async (requestParams: IFetchRulesRequest) => {
    const type = requestParams.type === 'onlyPendingRules' ? 'pending' : 'rules';
    try {
      setIsFetchingRules(prev => ({ ...prev, [type]: true }));

      const response = await getRules(requestParams);

      if (response.data) {
        setRulesData(prev => ({
          ...prev,
          [type]: response.data,
        }));
        setShowErrorScreen(prev => ({
          ...prev,
          [type]: false,
        }));
      }
    } catch (error) {
      setShowErrorScreen(prev => ({
        ...prev,
        [type]: true,
      }));
    } finally {
      setIsFetchingRules(prev => ({ ...prev, [type]: false }));
    }
  };

  return {
    isFetchingRules,
    fetchRulesData,
  };
}
