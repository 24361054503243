/* eslint-disable consistent-return */
export function getOidcStoraged() {
  const getOidcStorage =
    sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`) || '';
  return getOidcStorage ? JSON.parse(getOidcStorage) : undefined;
}

export function getToken() {
  const getOidcStorage =
    sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`) || '';
  const oidcStorage = getOidcStorage !== '' ? JSON.parse(getOidcStorage) : undefined;
  if (oidcStorage) {
    return oidcStorage.id_token;
  }
  return undefined;
}

export const getOperatorId = () => {
  const oidcStorage = getOidcStoraged();
  if (oidcStorage) {
    return oidcStorage.profile.operador;
  }
  return undefined;
};

export const getCodigoAbonado = () => {
  const oidcStorage = getOidcStoraged();
  if (oidcStorage) {
    return oidcStorage.profile.codigo;
  }
};

export const getAuthRole = () => {
  return sessionStorage.getItem('authProfile');
};

export const isUserAdmin = () => {
  return getAuthRole() === '83da1fef7e681fd3f5e5b6b8dd2ad533061cd42497fe412175a7afdb6924d5dc';
};
