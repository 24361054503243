/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Spinner, Text } from '@chakra-ui/react';
import { renderTextWithBreaks } from '@utils';
import { themeDefault } from '@wow/themes';
import { useEffect, useState } from 'react';

import customText from '../../lang/es.json';

const { colors } = themeDefault;

export interface LoadingProps {
  loading: boolean;
  loadingTitle1?: string;
  loadingSubtitle1?: string;
  disableTimer?: boolean;
}

export function FullscreenLoading({
  loading,
  loadingTitle1 = customText.fullLoadingScreen.titleDefault,
  loadingSubtitle1 = customText.fullLoadingScreen.description,
  disableTimer = false,
}: LoadingProps) {
  const [timerTriggered, setTimerTriggered] = useState(false);

  useEffect(() => {
    if (loading && !disableTimer) {
      document.body.style.overflow = 'hidden';
      const timer = setTimeout(() => {
        setTimerTriggered(true);
      }, 3000);
      return () => {
        clearTimeout(timer);
        document.body.style.overflow = 'auto';
        setTimerTriggered(false);
      };
    }
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [loading, disableTimer]);

  return (
    <>
      {loading && (
        <Box
          w='100vw'
          h='100vh'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          position='fixed'
          top={0}
          left={0}
          zIndex={100}
          alignItems='center'
          backgroundColor={colors.overlay}
        >
          <Spinner h='48px' w='48px' emptyColor='#7AABFF' color='#EBF0FF' variant='default' borderWidth='4px' mb='10px' />
          <Text textStyle='h4Bold' color='white'>
            {timerTriggered ? customText.fullLoadingScreen.titleSecond : loadingTitle1}
          </Text>
          <Text textStyle='bodySm' color='white' textAlign='center' fontSize='16px'>
            {timerTriggered ? customText.fullLoadingScreen.descriptionSecond : renderTextWithBreaks(loadingSubtitle1)}
          </Text>
        </Box>
      )}
    </>
  );
}
