/* eslint-disable dot-notation */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */

import {
  AuthorizeRequest,
  ICreateRulePayload,
  IFetchRulesRequest,
  IFundResponse,
  IGetRulesBanksResponse,
  IGetRulesResponse,
  IProfileRiskRequest,
  IProfileRiskResponse,
} from '@interfaces';
import { getToken } from '@utils';
import axios from 'axios';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((config: any) => {
  return {
    ...config,
    validateStatus: false,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  };
});

axiosInstance.interceptors.response.use((response: any) => {
  if (response.status === 403) {
    const res = {
      status: response.status,
      statusText: response.statusText,
      error: 'Access forbidden: You do not have permission to access this resource.',
    };
    throw res;
  } else if (response.status >= 400) {
    const error = new Error(response.data);
    const res = {
      status: response.status,
      statusText: response.statusText,
      error,
    };
    throw res;
  }
  return response;
});

const getBanks = () => {
  return axiosInstance.get<IGetRulesBanksResponse>(`${process.env.RULES_BASE_URL}banks`);
};

const postCreateRule = (payload: ICreateRulePayload) => {
  return axiosInstance.post(`${process.env.RULES_BASE_URL}rules`, payload);
};

const getRules = ({ pages, limit, type }: IFetchRulesRequest) => {
  if (type === 'onlyRules') {
    return axiosInstance.get<IGetRulesResponse>(`${process.env.RULES_BASE_URL}rules?page=${pages}&size=${limit}&authorized=true`);
  }
  if (type === 'onlyPendingRules') {
    return axiosInstance.get<IGetRulesResponse>(`${process.env.RULES_BASE_URL}rules?page=${pages}&size=${limit}&authorized=false`);
  }
  return axiosInstance.get<IGetRulesResponse>(`${process.env.RULES_BASE_URL}rules?page=${pages}&size=${limit}`);
};

const patchRule = (id: any, payload: any) => {
  return axiosInstance.patch(`${process.env.RULES_BASE_URL}rules/${id}`, payload);
};

const getInvestmentConfig = () => {
  return axiosInstance.get(`${process.env.RULES_BASE_URL}/configurations`);
};

const patchRules = (payload: any[]) => {
  return axiosInstance.patch(`${process.env.RULES_BASE_URL}rules`, payload);
};

const postAuthorizeRules = (payload: AuthorizeRequest[]) => {
  return axiosInstance.post(`${process.env.RULES_BASE_URL}rules/authorize`, payload);
};

const postProfileRisk = (payload: IProfileRiskRequest) => {
  return axiosInstance.post<IProfileRiskResponse>(`${process.env.INVESTMENT_BASE_URL}/order/profileRiskInvestor`, payload);
};

const postFundList = () => {
  return axiosInstance.post<IFundResponse[]>(`${process.env.INVESTMENT_BASE_URL}/subscription/list`);
};

const deleteRule = (id: string) => {
  return axiosInstance.delete(`${process.env.RULES_BASE_URL}rules?ruleId=${id}`);
};

export {
  deleteRule,
  getBanks,
  getInvestmentConfig,
  getRules,
  patchRule,
  patchRules,
  postAuthorizeRules,
  postCreateRule,
  postFundList,
  postProfileRisk,
};
