/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import { Text } from '@chakra-ui/react';
import { StateModal } from '@components';

import customText from '../../lang/es.json';

interface CloseModalProps {
  isOpen: boolean;
  onContinue: () => void;
  onClose: () => void;
}

export function CloseModal({ isOpen, onContinue, onClose }: CloseModalProps) {
  return (
    <StateModal
      headerState='warning'
      headerText={customText.closeModal.title}
      cancelButtonText={customText.closeModal.cancelButton}
      continueButtonText={customText.closeModal.acceptButton}
      isCancelButtonVisible
      isContinueButtonVisible
      isOpen={isOpen}
      onContinue={onContinue}
      onClose={onClose}
      isCentered
      isCloseButtonVisible
      size='md'
    >
      <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' sx={{ textWrap: 'auto' }}>
        {customText.closeModal.description}
      </Text>
    </StateModal>
  );
}
