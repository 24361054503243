/* eslint-disable import/prefer-default-export */
import { Skeleton, Stack } from '@chakra-ui/react';

export function CardSkeleton() {
  return (
    <Stack gap='32px'>
      <Stack gap='24px' mt='24px'>
        <Skeleton height='146px' />
        <Skeleton height='146px' />
        <Skeleton height='146px' />
      </Stack>
      <Skeleton height='56px' />
    </Stack>
  );
}
