/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { mockRulesData } from '@__mocks__';
import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { RuleCard, WelcomeWizardModal } from '@components';
import { TablePaginationComponent } from '@wow/table-pagination';
import { TabsBadge } from '@wow/tabs';
import { useEffect, useState } from 'react';

import customText from '../../lang/es.json';

export function SubscriptionRulesManagementWithWizard() {
  const [isVisible, setIsVisible] = useState(false);
  const [hasWelcome, setHasWelcome] = useState(false);
  const [hasModal, setHasModal] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('wizardRulesFromInvestStep0')) {
      setHasWelcome(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('wizardRulesFromInvestStep0') || localStorage.getItem('wizardRulesFromInvestStep1')) {
      setHasModal(true);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    // Limpia el temporizador si el componente se desmonta
    return () => clearTimeout(timer);
  }, []);

  return (
    isVisible && (
      <Box mt={22} mr={30} mb={30}>
        <Button
          // position='absolute'
          // right='60px'
          float='right'
          mt='-100px'
          height='40px'
          onClick={null}
          id='button-create-rule'
        >
          {customText.subscriptionRuleManagement.buttonCreateRule}
        </Button>

        <Tabs id='rules'>
          <TabList>
            <Tab data-id='tab-0'>{customText.tabs.rules.title}</Tab>

            <Tab data-id='tab-1'>
              {customText.tabs.authorizations.title}

              <TabsBadge meta metaLabel='1' />
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel fontFamily='Inter'>
              {mockRulesData.items?.map((item, index) => (
                <RuleCard
                  key={item.payload.id}
                  item={item}
                  isChecked={null}
                  onCheckboxChange={null}
                  type='rule'
                  fetchRules={null}
                  itemId={`rule-item-${index}`}
                />
              ))}

              <Box mt='15px'>
                <TablePaginationComponent
                  totalPages={1}
                  currentIndex={0}
                  totalRecords={3}
                  perPage={[15, 30, 45]}
                  disabledPerPage
                  currentPerPage={15}
                  onNext={() => {}}
                  onPrev={() => {}}
                  onFirst={() => null}
                  onLast={() => null}
                  onJump={value => {}}
                  onPerPageChange={value => {}}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <WelcomeWizardModal hasWelcome={hasWelcome} hasModal={!hasModal} />
      </Box>
    )
  );
}
