/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line no-shadow
import BigNumber from 'bignumber.js';

// eslint-disable-next-line no-shadow
export enum Money {
  USD = 'USD',
  ARS = 'ARS',
  NONE = 'NONE',
}

interface FormatMoney {
  value: number | undefined | null | string;
  thousandsSeparator?: string;
  decimals?: number;
  money?: Money;
  noMoney?: boolean;
  withSign?: boolean;
  secure?: boolean;
  deleteNegativeSign?: boolean;
  decimalsEnabled?: boolean;
}

export const formatMoney = ({
  value = 0,
  thousandsSeparator = '.',
  decimals = 2,
  money = Money.ARS,
  withSign = true,
  secure = false,
  noMoney = false,
  deleteNegativeSign = false,
}: FormatMoney) => {
  BigNumber.config({
    DECIMAL_PLACES: 50,
  });
  const safeValue = value === null ? 0 : value;
  const bigNumberValue = new BigNumber(safeValue);
  let formattedNumber = bigNumberValue.toFixed(decimals).replace('.', ',');
  const [integerPart, decimalPart] = formattedNumber.split(',');

  const formattedIntegerPart = new Intl.NumberFormat('de-DE', {
    useGrouping: true,
  }).format(Number(integerPart.replace(/,/g, '')));

  formattedNumber = decimalPart ? `${formattedIntegerPart},${decimalPart}` : formattedIntegerPart;

  if (secure) {
    formattedNumber = '****';
  }
  if (deleteNegativeSign) {
    formattedNumber = formattedNumber.replace('-', '');
  }
  if (!withSign) return formattedNumber;
  if (noMoney) return formattedNumber;

  return money === Money.USD ? `u$s ${formattedNumber}` : `$ ${formattedNumber}`;
};
