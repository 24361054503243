/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
import React, { SVGProps } from 'react';

export const BankIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.73007 7.6559L10.8772 1.38273C10.8771 1.38277 10.8772 1.38268 10.8772 1.38273C11.54 0.872469 12.46 0.872379 13.1228 1.38273L21.2699 7.6559C22.4215 8.54258 22.1196 10.2213 21 10.7988V17.2675C21.5978 17.6134 22 18.2597 22 19V21C22 22.1046 21.1046 23 20 23H4C2.89543 23 2 22.1046 2 21V19C2 18.2597 2.4022 17.6134 3 17.2675V10.7988L2.97202 10.7841C1.87571 10.1934 1.58831 8.53603 2.72916 7.6566C2.72947 7.65636 2.72977 7.65613 2.73007 7.6559ZM19.7373 8.99996L12 3.04236L4.26274 8.99996H19.7373ZM4 19L4 21H20V19H4ZM11 11H9V17H11V11ZM7 17H5V11H7V17ZM19 17H17V11H19V17ZM15 17H13V11H15V17Z'
        fill='#565E71'
      />
    </svg>
  );
};
