import { Link } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { BrowserRouter, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import notFoundImage from '../../assets/notfound.png';
import plainText from '../../lang/es.json';

interface IErrorPage {
  titleText?: string;
  bodyText: string;
  linkText?: string;
  callbackAction?: () => void;
  redirectTo?: string;
  onRedirect?: () => void;
  titleStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
}

function ErrorPage({ titleText, bodyText, linkText, callbackAction, redirectTo, onRedirect, titleStyle, bodyStyle }: IErrorPage) {
  return (
    <StyledErrorPage>
      <div className='error-page'>
        <div className='error-page__image' />

        <h4 style={titleStyle} className='error-page__title'>
          {titleText || plainText.errorPage.title}
        </h4>

        <p style={bodyStyle} className='error-page__body'>
          {bodyText}
        </p>

        {/* If a callback is passed as param it is executed onclick */}
        {callbackAction && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className='error-page__action' aria-hidden='true' onClick={() => callbackAction()}>
            {linkText || plainText.errorPage.link}
          </a>
        )}
        {/* Otherwise if a redirect is passed as param it is redirected onclick */}
        {redirectTo && (
          <BrowserRouter>
            <Link onClick={onRedirect} as={RouterLink} className='error-page__action' to={redirectTo}>
              {linkText || plainText.errorPage.link}
            </Link>
          </BrowserRouter>
        )}
      </div>
    </StyledErrorPage>
  );
}

export default ErrorPage;

const StyledErrorPage = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  flex-direction: column;

  .error-page {
    padding-top: 80px;
    &__image {
      background-image: url(${notFoundImage});
      background-position: center;
      background-size: cover;
      width: 120px; //232px;
      height: 120px; //192px;
      margin: auto;
    }
    &__title {
      font-family: Red Hat Display;
      margin-top: 15px;
      margin-bottom: 0px;
      font-size: 2em;
      margin: 15px;
      font-weight: 700;
      line-height: '2rem';
    }
    &__body {
      margin: 16px;
      font-weight: 400;
    }
    &__action {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      text-decoration-line: underline;
      color: #2172dc;
      cursor: pointer;
    }
  }
`;
