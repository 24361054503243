import { formatAccountType } from './ruleCard.utils';

/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
export const formatTime = (timeString: string): string => {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}`;
};

export const defaultOptionFormat = options => {
  return options
    .filter(option => option.enabledByBank)
    .map(option => ({
      label: option.name,
      value: option.id.toString().padStart(3, '0'),
      name: option.name,
      data: option,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const fundsOptionFormat = options => {
  return options
    .filter(option => option?.currency === 'ARS')
    .map(option => ({
      label: `${option.nameFund} ${option.clases.length !== 0 ? `- Clase ${option.clases}` : ''}`,
      value: option.idFundIB,
      idBank: option.idBank.toString().padStart(3, '0'),
      fundName: `${option.nameFund} ${option.clases.length !== 0 ? `- Clase ${option.clases}` : ''}`,
      fundType: option.type,
      period: option.periodAccreditation,
      operationTime: `${formatTime(option.openingHours)} a ${formatTime(option.closingHours)} horas`,
      timeStart: formatTime(option.openingHours),
      timeEnd: formatTime(option.closingHours),
      data: option,
    }))
    .sort((a, b) => a.fundName.localeCompare(b.fundName));
};

export const accountsOptionFormat = (options, type, bankId, fundId) => {
  if (type === 'ACCOUNTS') {
    return options.accounts
      .map(option => ({
        label: `${formatAccountType(option.accountType)} | ${option.currency} | ${option.accountId}`,
        value: option.accountId,
        data: { ...option, bankId, fundId },
      }))
      .sort((a, b) => a.value.localeCompare(b.value));
  }
  if (type === 'BROKERAGE') {
    return options.investors
      .map(option => ({
        label: option.investorNumber,
        value: option.investorNumber,
        data: { ...option, bankId, fundId },
      }))
      .sort((a, b) => a.value.localeCompare(b.value));
  }
};

export const convertDayNumbers = (days: string[] | string): number[] => {
  const dayMapping: { [key: string]: number } = {
    Lunes: 1,
    Martes: 2,
    Miércoles: 3,
    Jueves: 4,
    Viernes: 5,
  };

  const daysValues = Array.isArray(days) ? days : [days];

  return daysValues
    .map(day => dayMapping[day])
    .filter(dayNumber => dayNumber !== undefined)
    .sort((a, b) => a - b);
};

export const convertTimeToHour = (time: string): number => {
  const [hour] = time.split(':');
  return parseInt(hour, 10);
};
