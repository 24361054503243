/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { useToast } from '@chakra-ui/react';
import { IFundResponse } from '@interfaces';
import { postFundList } from '@services/api';
import { ToastComponent } from '@wow/toast';
import { useState } from 'react';

import customText from '../lang/es.json';

export function useFetchFunds(setRuleFundData: (data: IFundResponse[]) => void) {
  const [isFetchingFunds, setIsFetchingFunds] = useState(false);
  const toast = useToast({
    containerStyle: {
      marginLeft: '60px',
    },
  });

  const fetchFundData = async (bankId: number) => {
    try {
      setIsFetchingFunds(true);
      const response = await postFundList();

      if (response.data) {
        setRuleFundData(response.data.filter(item => item.idBank === bankId));
      }
    } catch (error) {
      showErrorToast(bankId);
    } finally {
      setIsFetchingFunds(false);
    }
  };

  const showErrorToast = bankId => {
    const toastId = toast({
      duration: 10000,
      position: 'bottom-left',
      status: 'error',
      description: customText.home.toast.error.getBanks,
      isClosable: true,

      render: (props: any) => (
        <ToastComponent
          {...props}
          isCtaLabel
          ctaLabel={customText.home.toast.error.retryButton}
          ctaOnClick={() => {
            toast.close(toastId);
            fetchFundData(bankId);
          }}
        />
      ),
    });
  };

  return {
    isFetchingFunds,
    fetchFundData,
  };
}
