/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
import { Box } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';

export const StyledBox = styled(Box)<{ isExpanded: boolean }>`
  background-color: #ffffff;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 72px;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isExpanded }) => (isExpanded ? '16px 60px 16px 130px' : '12px 50px 12px 130px')};
  box-shadow: 0px 25px 50px -12px rgba(19, 28, 44, 0.85);
`;

interface BottomNavProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  isExpanded?: boolean;
}

// eslint-disable-next-line react/function-component-definition
export const BottomNav: React.FC<BottomNavProps> = ({ leftContent, rightContent, isExpanded = false }) => {
  return (
    <StyledBox data-testid='bottomnav-wrapper' isExpanded={isExpanded}>
      {leftContent}
      {rightContent}
    </StyledBox>
  );
};
