export const rulesData = [
  {
    bankId: '007',
    bankName: 'Banco Galicia',
    accountDetails: 'CC | ARS | 2383844',
    fund: 'Fondo Fima Premium - Clase B Ahorro Plus',
    amount: '$ 400.000,00',
    frequency: 'Todos los días',
    schedule: '10:30',
    status: 'Activa',
    authorizers: '2',
  },
  {
    bankId: '016',
    bankName: 'Banco CitiBank',
    accountDetails: 'CC | ARS | 3383844',
    fund: 'Fondo Alpha Pesos Plus',
    amount: '$ 2.000.000,00',
    frequency: 'Todos los días',
    schedule: '9:30',
    status: 'Pausada',
    authorizers: '1',
  },
  {
    bankId: '016',
    bankName: 'Banco CitiBank',
    accountDetails: 'CC | ARS | 3383845',
    fund: 'Fondo Alpha Pesos Plus',
    amount: '$ 60.000,00',
    frequency: 'Todos los días',
    schedule: '11:30',
    status: 'Creada',
    authorizers: '3',
  },
];

export const pendingAuthRules = [
  {
    bankId: '007',
    bankName: 'Banco Galicia',
    accountDetails: 'CC | ARS | 2383844',
    fund: 'Fondo Fima Premium - Clase B Ahorro Plus',
    amount: '$ 400.000,00',
    frequency: 'Todos los días',
    schedule: '10:30',
    status: 'Activa',
    authorizers: '2',
  },
  {
    bankId: '016',
    bankName: 'Banco CitiBank',
    accountDetails: 'CC | ARS | 3383844',
    fund: 'Fondo Alpha Pesos Plus',
    amount: '$ 2.000.000,00',
    frequency: 'Todos los días',
    schedule: '9:30',
    status: 'Pausada',
    authorizers: '1',
  },
];

export const mockRulesData = {
  items: [
    {
      timeOfDay: 10,
      daysOfWeek: [1, 2, 3, 4, 5],
      status: 'ACTIVE',
      payload: {
        id: '01946fd7-e663-7445-a653-4049a75aa4d9',
        type: 'RuleSubscriptionPayload',
        subscriberCode: 'X32384A',
        accountMinimumBalance: 50000,
        authorizationStatus: 'AUTHORIZED',
        totalAuthorizers: 1,
        bank: {
          id: 0,
          name: 'Banco A',
          enabledByBank: true,
          configId: 885,
        },
        account: {
          id: '001509310',
          type: 'CUENTA CORRIENTE',
          currencyCode: 'ARS',
        },
        fund: {
          id: 769,
          name: 'Fondo 1',
          type: '',
          risk: 'Agresivo',
          settlementDays: '24 horas',
          openingHours: '06:15:00.000-03:00',
          closingHours: '16:00:00.000-03:00',
          managementRegulationUrl:
            'https://www.fondosalpha.icbc.com.ar/alpha/wps/wcm/connect/acfba8004efd86c09234da4bad6f8c6c/Reglamento%20Alpha%20Mega_TO.pdf?MOD=AJPERES',
          fundClass: 'A',
        },
        investorAccount: {
          investorNumber: '00139496702',
        },
        operatorsBasedInActions: [
          {
            action: 'CREATE',
            operator: {
              document: '20284806851',
              fullName: 'Estefano Trigona',
              principalId: 'user',
              subscriberCode: 'X32384A',
              businessSocialReason: 'empdos',
              companyRelation: '30761760',
              integrationLatency: 1408,
              personCode: '449202',
              profilePermissions:
                '["Inversiones.FondoComunDeInversion.Consulta.Tenencia","Inversiones.FondoComunDeInversion.AutorizarSolicitudes","Inversiones.FondoComunDeInversion.Consulta.Mercado","Inversiones.FondoComunDeInversion.CargarSolicitudes"]',
            },
            createdAt: '2025-01-16T16:00:22.690Z',
          },
          {
            action: 'AUTHORIZE',
            operator: {
              document: '20284806851',
              fullName: 'Estefano Trigona',
              principalId: 'user',
              subscriberCode: 'X32384A',
              businessSocialReason: 'empdos',
              companyRelation: '30761760',
              integrationLatency: 1733,
              personCode: '449202',
              profilePermissions:
                '["Inversiones.FondoComunDeInversion.Consulta.Tenencia","Inversiones.FondoComunDeInversion.AutorizarSolicitudes","Inversiones.FondoComunDeInversion.Consulta.Mercado","Inversiones.FondoComunDeInversion.CargarSolicitudes"]',
            },
            createdAt: '2025-01-16T16:00:22.690Z',
          },
        ],
      },
      createdAt: '2025-01-16T16:00:07.952Z',
      updatedAt: '2025-01-16T16:00:22.808Z',
    },
    {
      timeOfDay: 0,
      daysOfWeek: [1, 2, 3, 4, 5],
      status: 'INACTIVE',
      payload: {
        id: '01946ba5-29ae-7006-8493-6abe7a93b2ce',
        type: 'RuleSubscriptionPayload',
        subscriberCode: 'X32384A',
        accountMinimumBalance: 1000,
        authorizationStatus: 'PENDING',
        totalAuthorizers: 2,
        bank: {
          id: 0,
          name: 'Banco B',
          enabledByBank: true,
          configId: 884,
        },
        account: {
          id: '0/0334/018',
          type: 'CUENTA CORRIENTE',
          currencyCode: 'ARS',
        },
        fund: {
          id: 8,
          name: 'Fondo 2',
          type: '',
          risk: '',
          settlementDays: 'inmediato',
          openingHours: '07:00:00.000-03:00',
          closingHours: '18:00:00.000-03:00',
          managementRegulationUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
          fundClass: 'B',
        },
        investorAccount: {
          investorNumber: '33844',
        },
        operatorsBasedInActions: [
          {
            action: 'CREATE',
            operator: {
              document: '20284806851',
              fullName: 'Estefano Trigona',
              principalId: 'user',
              subscriberCode: 'X32384A',
              businessSocialReason: 'empdos',
              companyRelation: '30761760',
              integrationLatency: 1572,
              personCode: '449202',
              profilePermissions:
                '["Inversiones.FondoComunDeInversion.Consulta.Tenencia","Inversiones.FondoComunDeInversion.AutorizarSolicitudes","Inversiones.FondoComunDeInversion.Consulta.Mercado","Inversiones.FondoComunDeInversion.CargarSolicitudes"]',
            },
            createdAt: '2025-01-16T15:58:51.889Z',
          },
          {
            action: 'AUTHORIZE',
            operator: {
              document: '20284806851',
              fullName: 'Estefano Trigona',
              principalId: 'user',
              subscriberCode: 'X32384A',
              businessSocialReason: 'empdos',
              companyRelation: '30761760',
              integrationLatency: 1250,
              personCode: '449202',
              profilePermissions:
                '["Inversiones.FondoComunDeInversion.Consulta.Tenencia","Inversiones.FondoComunDeInversion.AutorizarSolicitudes","Inversiones.FondoComunDeInversion.Consulta.Mercado","Inversiones.FondoComunDeInversion.CargarSolicitudes"]',
            },
            createdAt: '2025-01-16T15:58:51.889Z',
          },
        ],
      },
      createdAt: '2025-01-15T20:26:14.392Z',
      updatedAt: '2025-01-15T20:26:14.392Z',
    },
    {
      timeOfDay: 7,
      daysOfWeek: [1],
      status: 'INACTIVE',
      payload: {
        id: '01946fd5-ee6a-7445-a653-1738ce8af5b1',
        type: 'RuleSubscriptionPayload',
        subscriberCode: 'X32384A',
        accountMinimumBalance: 25000,
        authorizationStatus: 'AUTHORIZED',
        totalAuthorizers: 1,
        bank: {
          id: 0,
          name: 'Banco C',
          enabledByBank: true,
          configId: 885,
        },
        account: {
          id: '0015093106',
          type: 'CUENTA CORRIENTE',
          currencyCode: 'ARS',
        },
        fund: {
          id: 12,
          name: 'Fondo 3',
          type: '',
          risk: 'Agresivo',
          settlementDays: '24 horas',
          openingHours: '06:15:00.000-03:00',
          closingHours: '16:00:00.000-03:00',
          managementRegulationUrl:
            'https://www.fondosalpha.icbc.com.ar/alpha/wps/wcm/connect/fcf064004efd86ec9272da4bad6f8c6c/Reglamento%20Alpha%20Acciones%20RG_TO.pdf?MOD=AJPERES',
          fundClass: 'C',
        },
        investorAccount: {
          investorNumber: '00121380507',
        },
        operatorsBasedInActions: [
          {
            action: 'CREATE',
            operator: {
              document: '20284806851',
              fullName: 'Estefano Trigona',
              principalId: 'user',
              subscriberCode: 'X32384A',
              businessSocialReason: 'empdos',
              companyRelation: '30761760',
              integrationLatency: 1333,
              personCode: '449202',
              profilePermissions:
                '["Inversiones.FondoComunDeInversion.Consulta.Tenencia","Inversiones.FondoComunDeInversion.AutorizarSolicitudes","Inversiones.FondoComunDeInversion.Consulta.Mercado","Inversiones.FondoComunDeInversion.CargarSolicitudes"]',
            },
            createdAt: '2025-01-16T15:58:12.670Z',
          },
          {
            action: 'AUTHORIZE',
            operator: {
              document: '20284806851',
              fullName: 'Estefano Trigona',
              principalId: 'user',
              subscriberCode: 'X32384A',
              businessSocialReason: 'empdos',
              companyRelation: '30761760',
              integrationLatency: 1266,
              personCode: '449202',
              profilePermissions:
                '["Inversiones.FondoComunDeInversion.Consulta.Tenencia","Inversiones.FondoComunDeInversion.AutorizarSolicitudes","Inversiones.FondoComunDeInversion.Consulta.Mercado","Inversiones.FondoComunDeInversion.CargarSolicitudes"]',
            },
            createdAt: '2025-01-16T15:58:12.670Z',
          },
        ],
      },
      createdAt: '2025-01-16T15:58:00.691Z',
      updatedAt: '2025-01-16T15:58:33.588Z',
    },
  ],
  totalPages: 1,
  totalRows: 3,
};

export const mockPendingRulesData = {
  items: [
    {
      timeOfDay: 9,
      daysOfWeek: [3],
      status: 'INACTIVE',
      payload: {
        id: '01946fd9-db39-7445-a653-73c7fd97d9fc',
        type: 'RuleSubscriptionPayload',
        subscriberCode: 'X32384A',
        accountMinimumBalance: 10000,
        authorizationStatus: 'PENDING',
        totalAuthorizers: 1,
        bank: {
          id: 15,
          name: 'ICBC',
          enabledByBank: true,
          configId: 885,
        },
        account: {
          id: '00150931000200272246',
          type: 'CUENTA CORRIENTE',
          currencyCode: 'ARS',
        },
        fund: {
          id: 10,
          name: 'Fondo 2',
          type: 'RENTA FIJA PESOS',
          risk: 'Conservador',
          settlementDays: '24 horas',
          openingHours: '06:15:00.000-03:00',
          closingHours: '16:00:00.000-03:00',
          managementRegulationUrl:
            'https://www.fondosalpha.icbc.com.ar/alpha/wps/wcm/connect/dcd5b2004efd7d888ecede4bad6f8c6c/Reglamento%20Alpha%20Ahorro_TO.pdf?MOD=AJPERES',
          fundClass: '',
        },
        investorAccount: {
          investorNumber: '00139496606',
        },
        operatorsBasedInActions: [
          {
            action: 'CREATE',
            operator: {
              document: '20284806851',
              fullName: 'Estefano Trigona',
              principalId: 'user',
              subscriberCode: 'X32384A',
              businessSocialReason: 'empdos',
              companyRelation: '30761760',
              integrationLatency: 1412,
              personCode: '449202',
              profilePermissions:
                '["Inversiones.FondoComunDeInversion.Consulta.Tenencia","Inversiones.FondoComunDeInversion.AutorizarSolicitudes","Inversiones.FondoComunDeInversion.Consulta.Mercado","Inversiones.FondoComunDeInversion.CargarSolicitudes"]',
            },
            createdAt: '2025-01-16T16:02:15.996Z',
          },
        ],
      },
      createdAt: '2025-01-16T16:02:16.083Z',
      updatedAt: '2025-01-16T16:02:16.083Z',
    },
  ],
  totalPages: 1,
  totalRows: 1,
};
