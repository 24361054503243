/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-array-index-key */
import { Box, Button, HStack, Image, Menu, MenuButton, MenuItem, MenuList, useDisclosure, VStack } from '@chakra-ui/react';
import { IActionDropdownProps, IOption } from '@interfaces';
import { themeChakra } from '@wow/themes';
// import { getImageByBank } from '@utils'
import { Calendar, ChevronDown, ChevronUp, Clock } from 'react-feather';

import customText from '../../lang/es.json';

export function ActionDropdown({
  type = 'default',
  placeholder = 'Select option',
  inputTitle = '',
  data = [],
  isDisabled,
  onChange,
  selectedOption,
}: IActionDropdownProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelectOption = (option: any) => {
    onClose();
    if (onChange) {
      onChange(option);
    }
  };

  const generateOption = (item: IOption) => {
    if (type && type === 'fund') {
      return (
        <VStack alignItems='start'>
          <Box>
            {item.label} | {item.fundType}
          </Box>
          <HStack fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontSize='12px' color='#656E84'>
            <Calendar height={16} width={16} />
            <Box>
              {customText.steps.createRule.actionDropdown.fundAcreditationText} {item.period}
            </Box>
            <Clock height={16} width={16} />
            <Box>
              {customText.steps.createRule.actionDropdown.fundScheduleText} {item.operationTime}
            </Box>
          </HStack>
        </VStack>
      );
    }

    if (type && type === 'bank') {
      return (
        <Box display='flex' alignItems='center' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily}>
          <Image
            width='40px'
            height='40px'
            borderRadius='full'
            src={`${process.env.URL_BANK_LOGOS}logo${item.value}.png`}
            alt={item.label}
            mr='15px'
            border='1px solid #565E71'
          />
          {item.label}
        </Box>
      );
    }

    return item.label;
  };

  const generateMenuItems = (items: IOption[]) => {
    return items.map((item: IOption, index: number) => (
      <MenuItem
        key={index}
        onClick={() => handleSelectOption(item)}
        padding={type === 'bank' ? '8px 12px' : '13px 12px'}
        fontSize='14px'
        fontFamily={themeChakra.textStyles.titleMdBold.fontFamily}
        sx={{
          _hover: {
            background: 'rgba(0, 0, 0, 0.06)',
          },
          _active: {
            background: 'white',
          },
          _focusVisible: {
            background: 'white',
          },
          _focus: {
            background: 'white',
          },
        }}
      >
        {generateOption(item)}
      </MenuItem>
    ));
  };

  return (
    <VStack gap={0} alignItems='start' width='100%' zIndex={isOpen ? 1 : 0}>
      <Box fontSize='13px' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} color='#2F3034' opacity={isDisabled ? 0.4 : 1}>
        {inputTitle}
      </Box>
      <Menu matchWidth isOpen={isOpen} onClose={onClose}>
        <MenuButton
          as={Button}
          rightIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
          onClick={isOpen ? onClose : onOpen}
          fontSize='14px'
          fontFamily={themeChakra.textStyles.titleMdBold.fontFamily}
          fontWeight={400}
          color='#44474F'
          height='36px'
          border='1px solid rgb(116, 119, 127)'
          textAlign='left'
          width='100%'
          marginTop='0px !important'
          sx={{
            bg: 'white',
            color: 'black',
            _hover: !isDisabled
              ? {
                  background: 'rgba(0, 0, 0, 0.06)',
                  border: '1px solid rgb(116, 119, 127)',
                  boxShadow: 'none',
                }
              : {},

            _active: !isDisabled
              ? {
                  background: 'rgba(0, 0, 0, 0.16)',
                }
              : {},
            _focusVisible: {
              background: 'rgba(0, 0, 0, 0.16)',
              boxShadow: 'none',
            },
            _disabled: {
              background: 'white',
              border: '1px solid #74777F',
              boxShadow: 'none',
              opacity: 0.4,
              cursor: 'not-allowed',
            },
          }}
          isDisabled={isDisabled}
        >
          {selectedOption ? selectedOption.label : placeholder}
        </MenuButton>
        <MenuList
          marginTop='-7px'
          marginBottom='-7px'
          position='relative'
          padding='4px'
          maxHeight='200px'
          overflow='auto'
          boxShadow='0px 4px 6px -2px #131C2C0D, 0px 10px 15px -3px #131C2C1A'
          borderRadius='12px'
          background='white'
          sx={{
            transition: 'transform 0.2ms fade-out !important',
            transform: isOpen ? 'scale(1)' : 'scale(1) !important',
            opacity: isOpen ? 1 : 0.9,
            transformOrigin: 'top !important',
          }}
        >
          {generateMenuItems(data)}
        </MenuList>
      </Menu>
    </VStack>
  );
}
