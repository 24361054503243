/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { useToast } from '@chakra-ui/react';
import { IProfileRiskResponse } from '@interfaces';
import { postProfileRisk } from '@services/api';
import { ToastComponent } from '@wow/toast';
import { useState } from 'react';

import customText from '../lang/es.json';

export function useFetchProfileRisk(setProfileRiskData: (data: IProfileRiskResponse) => void) {
  const [isFetchingProfileRisk, setIsFetchingProfileRisk] = useState(false);
  const toast = useToast({
    containerStyle: {
      marginLeft: '60px',
    },
  });

  const fetchProfileRiskData = async (bankId: number, fundId: number) => {
    try {
      setIsFetchingProfileRisk(true);
      const response = await postProfileRisk({ idBank: bankId, idFundIB: fundId });

      if (response.data) {
        setProfileRiskData(response.data);
      }
    } catch (error) {
      showErrorToast(bankId, fundId);
    } finally {
      setIsFetchingProfileRisk(false);
    }
  };

  const showErrorToast = (bankId, fundId) => {
    const toastId = toast({
      duration: 10000,
      position: 'bottom-left',
      status: 'error',
      description: customText.home.toast.error.getBanks,
      isClosable: true,

      render: (props: any) => (
        <ToastComponent
          {...props}
          isCtaLabel
          ctaLabel={customText.home.toast.error.retryButton}
          ctaOnClick={() => {
            toast.close(toastId);
            fetchProfileRiskData(bankId, fundId);
          }}
        />
      ),
    });
  };

  return {
    isFetchingProfileRisk,
    fetchProfileRiskData,
  };
}
