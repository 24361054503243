/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import { Box, Button } from '@chakra-ui/react';
import { FullscreenLoading, SubscriptionRulesNoTabs, SubscriptionRulesTabs } from '@components';
import { useFetchBanks, useFetchRules } from '@hooks';
import { IFetchRulesRequest } from '@interfaces';
import { useContext, useEffect } from 'react';

import customText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';

export function SubscriptionRulesManagement() {
  const {
    hasAuthPermission,
    hasCreateSubcriptionPermission,
    setRuleBankData,
    setRulesData,
    rulesData,
    setShowErrorScreen,
    showErrorScreen,
    setRulesToAuthorize,
  } = useContext(AppContext) as any;

  const { isFetchingBanks, fetchBanksData } = useFetchBanks(setRuleBankData);
  const { isFetchingRules, fetchRulesData } = useFetchRules(setRulesData, setShowErrorScreen);

  useEffect(() => {
    if (hasAuthPermission) {
      handleFetchRules({ pages: 1, limit: 15, type: 'all' });
      handleFetchPendingRules({ pages: 1, limit: 15, type: 'onlyPendingRules' });
    } else {
      handleFetchRules({ pages: 1, limit: 15, type: 'all' });
    }
  }, []);

  useEffect(() => {
    setRulesToAuthorize([]);
  }, []);

  const handleFetchRules = ({ pages, limit, type }: IFetchRulesRequest) => {
    fetchRulesData({ pages, limit, type });
  };

  const handleFetchPendingRules = ({ pages, limit, type }: IFetchRulesRequest) => {
    fetchRulesData({ pages, limit, type });
  };

  const handleCreateRule = () => {
    fetchBanksData();
  };

  return (
    <Box mt={22} mr={30} mb={30}>
      {hasCreateSubcriptionPermission &&
        !isFetchingRules.rules &&
        ((rulesData?.rules && rulesData?.rules?.items?.length !== 0) || showErrorScreen?.rules) && (
          <Button
            float='right'
            mt={!hasAuthPermission && showErrorScreen?.rules ? '-130px' : '-100px'}
            height='40px'
            onClick={handleCreateRule}
            id='button-create-rule'
          >
            {customText.subscriptionRuleManagement.buttonCreateRule}
          </Button>
        )}

      {hasCreateSubcriptionPermission && !hasAuthPermission ? (
        <SubscriptionRulesNoTabs
          data={rulesData?.rules}
          isLoading={isFetchingRules?.rules}
          fetchRules={handleFetchRules}
          createRule={handleCreateRule}
        />
      ) : (
        <SubscriptionRulesTabs
          data={rulesData}
          isLoading={isFetchingRules.rules}
          fetchRules={handleFetchRules}
          createRule={handleCreateRule}
          fetchPendingRules={handleFetchPendingRules}
          isLoadingPending={isFetchingRules.pending}
          screenErrorRules={showErrorScreen?.rules}
          screenErrorPending={showErrorScreen?.pending}
        />
      )}
      {isFetchingBanks && (
        <FullscreenLoading
          loading={isFetchingBanks}
          loadingTitle1={customText.fullLoadingScreen.title}
          loadingSubtitle1={customText.fullLoadingScreen.description}
        />
      )}
    </Box>
  );
}
