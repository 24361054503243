/* eslint-disable import/prefer-default-export */
import { Box, Button } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import styled from 'styled-components';

interface IStateContentScreen {
  IconState?: string;
  titleText?: string;
  bodyText?: string;
  buttonText?: string;
  buttonAction?: () => void;
  titleStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
}

export function StateContentScreen({
  IconState,
  titleText,
  bodyText,
  buttonText,
  buttonAction,
  titleStyle,
  bodyStyle,
}: IStateContentScreen) {
  return (
    <StyledStateContentScreen>
      <Box className='state-screen'>
        <img src={IconState} className='state-screen__image' alt='state' />

        <h4 style={titleStyle} className='state-screen__title'>
          {titleText}
        </h4>

        <p style={bodyStyle} className='state-screen__body'>
          {bodyText}
        </p>

        <Button variant='link' color='#2172DC' textDecoration='underline' textUnderlineOffset='3px' onClick={buttonAction}>
          {buttonText}
        </Button>
      </Box>
    </StyledStateContentScreen>
  );
}

const StyledStateContentScreen = styled.div`
  .state-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    &__image {
      margin-bottom: 17px;
    }

    &__title {
      font-family: 'Red Hat Display', sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 7px;
    }

    &__body {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 28px;
    }
  }
`;
