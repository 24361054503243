/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
import React, { SVGProps } from 'react';

export const InversionesIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.03345 17.775V21.9917C4.03345 22.5417 3.57974 23 3.0353 23C2.49086 23 2.03716 22.5417 2.03716 21.9917V17.775C2.03716 17.225 2.49086 16.7667 3.0353 16.7667C3.57974 16.675 4.03345 17.225 4.03345 17.775ZM9.02416 13.5583C8.47971 13.5583 8.02601 14.0167 8.02601 14.475V22.0833C8.02601 22.6333 8.47971 23 9.02416 23C9.5686 23 10.0223 22.5417 10.0223 22.0833V14.475C10.0223 14.0167 9.5686 13.5583 9.02416 13.5583ZM15.013 12.55C14.4686 12.55 14.0149 13.0083 14.0149 13.5583V21.9C14.0149 22.45 14.4686 22.9083 15.013 22.9083C15.5574 22.9083 16.0111 22.45 16.0111 21.9V13.5583C16.0111 13.0083 15.5574 12.55 15.013 12.55ZM21.0019 11.45C20.4574 11.45 20.0037 11.9083 20.0037 12.4583V21.9C20.0037 22.45 20.4574 22.9083 21.0019 22.9083C21.5463 22.9083 22 22.45 22 21.9V12.55C22 11.9083 21.5463 11.45 21.0019 11.45ZM21.0019 1H15.013C14.4686 1 14.0149 1.45833 14.0149 2.00833C14.0149 2.55833 14.4686 3.01667 15.013 3.01667H18.4611L14.5593 6.68333L8.93342 7.325C8.75194 7.325 8.66119 7.325 8.47971 7.41667L2.49086 11.0833C2.03716 11.3583 1.85568 12 2.1279 12.55C2.40012 13.0083 3.0353 13.1917 3.489 12.9167L9.29638 9.34167L15.1037 8.88333C15.2852 8.88333 15.5574 8.79167 15.6482 8.60833L19.913 4.575L20.0037 4.66667V8.33333C20.0037 8.88333 20.4574 9.34167 21.0019 9.34167C21.5463 9.34167 22 8.88333 22 8.33333V2.00833C22 1.45833 21.5463 1 21.0019 1Z'
        fill='#565E71'
      />
    </svg>
  );
};
