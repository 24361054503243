/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, CloseButton, Text, useDisclosure } from '@chakra-ui/react';
import { wizardSteps, wizardStepsWIthStep0 } from '@utils';
import {
  Modal,
  ModalBody,
  ModalBox,
  ModalChakra,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLeftBox,
  ModalOverlay,
  ModalRightBox,
} from '@wow/modal';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useContext, useEffect } from 'react';

import welcomeImage from '../../assets/Imagen-modal.svg';
import { AppContext } from '../../providers/AppContextProvider';

export function WelcomeWizardModal({ hasWelcome, hasModal }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();

  const { setWizardCompleted } = useContext(AppContext) as any;

  const handleFinishWizard = () => {
    localStorage.setItem('wizardRulesInvCompleted', 'true');
    setWizardCompleted(true);
  };

  useEffect(() => {
    if (localStorage.getItem('wizardRulesInvCompleted') == null && hasModal) {
      onOpen();
    } else {
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onClose();
    handleFinishWizard();
    setWizardCompleted(true);
  };

  const handleExplore = () => {
    onClose();
    setInitialized(true);
  };

  return (
    <>
      {hasModal && (
        <Modal isOpen={isOpen} onClose={() => {}} variant='welcome' isCentered autoFocus={false} lockFocusAcrossFrames={false}>
          <ModalOverlay />
          <ModalContent>
            <CloseButton onClick={handleClose} sx={ModalChakra.close} />
            <ModalBox>
              <ModalLeftBox width='74%' paddingRight='12px'>
                <ModalHeader>
                  <Text textStyle='h3' fontSize='28px' sx={{ textWrap: 'auto' }}>
                    ¡Automatizar tus inversiones es posible!
                  </Text>
                </ModalHeader>
                <ModalBody>
                  <Text sx={{ textWrap: 'auto' }} letterSpacing='normal'>
                    Ahora podés crear reglas para automatizar tus suscripciones a fondos y agilizar tu operatoria.
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button variant='primary' onClick={() => handleExplore()} height='40px !important' fontSize='13px'>
                    Explorar
                  </Button>
                </ModalFooter>
              </ModalLeftBox>
              <ModalRightBox marginTop='32px'>
                <img src={welcomeImage} alt='img' width='287px !important' height='177px !important' />
              </ModalRightBox>
            </ModalBox>
          </ModalContent>
        </Modal>
      )}
      <WizardComponent
        id='wizard-profile'
        steps={hasWelcome ? wizardStepsWIthStep0 : wizardSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        initialized={initialized}
        setInitialized={setInitialized}
        width={305}
        height={220}
        offset={20}
        initialLabel={hasWelcome ? 'Empezar guía' : ''}
        finishLabel='Finalizar'
        onFinish={handleFinishWizard}
        hasWelcome={hasWelcome}
      />
    </>
  );
}
