/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import emptyStateIcon from '@assets/rocket.svg';
import errorStateIcon from '@assets/underConstruction.svg';
import { Box } from '@chakra-ui/react';
import { CardSkeleton } from '@components/CardSkeleton/CardSkeleton.component';
import { RuleCard } from '@components/RuleCard/RuleCard.component';
import { StateContentScreen } from '@components/StateContentScreen/StateContentScreen.component';
import { IPagination, ISubscriptionRulesNoTabs } from '@interfaces';
import { AppContext } from '@providers/AppContextProvider';
import { TablePaginationComponent } from '@wow/table-pagination';
import { useCallback, useContext, useEffect, useState } from 'react';

import customText from '../../lang/es.json';

export function SubscriptionRulesNoTabs({ data, isLoading = false, fetchRules, createRule }: ISubscriptionRulesNoTabs) {
  const { showErrorScreen } = useContext(AppContext) as any;

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 0,
    elementsPerPage: 0,
    totalElements: 0,
    totalPages: 0,
  });

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);

  useEffect(() => {
    if (data) {
      setPagination({
        currentPage: page,
        elementsPerPage: size,
        totalElements: data?.totalRows,
        totalPages: data?.totalPages,
      });
    }
  }, [data, page, size]);
  const onChangePagination = useCallback(() => {
    fetchRules({
      pages: page + 1,
      limit: size,
      type: 'all',
    });
  }, [fetchRules, page, size]);

  useEffect(() => {
    onChangePagination();
  }, [page, size]);

  const handleFetchRules = () => {
    fetchRules({ pages: 1, limit: 15, type: 'all' });
  };

  return (
    <>
      {!isLoading && data && data?.items?.length !== 0 ? (
        <>
          {data?.items.map((item, index) => (
            <RuleCard key={index} item={item} type='noAuth' itemId={`rule-item-${index}`} />
          ))}
          <Box mt='15px'>
            <TablePaginationComponent
              totalPages={pagination.totalPages || 1}
              currentIndex={pagination.currentPage}
              totalRecords={pagination.totalElements}
              perPage={[15, 30, 45]}
              disabledPerPage={pagination.totalElements <= 15}
              currentPerPage={pagination.elementsPerPage}
              onNext={() => {
                setPage(page + 1);
              }}
              onPrev={() => setPage(page - 1)}
              onFirst={() => setPage(0)}
              onLast={() => setPage(pagination.totalPages - 1)}
              onJump={value => {
                setPage(Number(value) - 1);
              }}
              onPerPageChange={value => {
                setSize(Number(value));
                setPage(0);
              }}
            />
          </Box>
        </>
      ) : !isLoading && data && data?.items?.length === 0 ? (
        <StateContentScreen
          IconState={emptyStateIcon}
          titleText={customText.emptyStateScreen.title}
          bodyText={customText.emptyStateScreen.description}
          buttonText={customText.emptyStateScreen.buttonText}
          buttonAction={createRule}
          data-testid='empty-state-content'
        />
      ) : !isLoading && showErrorScreen ? (
        <StateContentScreen
          IconState={errorStateIcon}
          titleText={customText.subscriptionRuleManagement.emptyStateError.title}
          bodyText={customText.subscriptionRuleManagement.emptyStateError.description}
          buttonText={customText.subscriptionRuleManagement.emptyStateError.buttonText}
          buttonAction={handleFetchRules}
          data-testid='empty-state-content'
        />
      ) : (
        <CardSkeleton />
      )}
    </>
  );
}
