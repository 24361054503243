/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { Box, HStack, Text } from '@chakra-ui/react';
import { AuthorizationAccordion, Breadcrumb } from '@components';
import { useAuthorizeRules } from '@hooks';
import { IAuthorizationResponse, IRuleSubscriptionList } from '@interfaces';
import { AppContext } from '@providers/AppContextProvider';
import { generateBreadCrumbRoutes } from '@utils';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import customText from '../../lang/es.json';

export function AuthorizationResults() {
  const breadcrumbCustomLabels = ['Inicio', 'Reglas automáticas', 'Resultado'];
  const navigate = useNavigate();
  const location = useLocation();
  const { setShowErrorScreen } = useContext(AppContext) as any;

  const [data, setData] = useState<IAuthorizationResponse>(location.state?.authorizationResult || []);

  const { isPatchingRules, authorizeData } = useAuthorizeRules(setShowErrorScreen);

  const handleReAuthorize = async (itemsToAuthorize: IRuleSubscriptionList[]) => {
    const ruleIds = itemsToAuthorize.map(item => item.payload.id);
    try {
      const reAuthorizationResult = await authorizeData(ruleIds);
      setData(reAuthorizationResult);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box ml={30} mr={30} mt={1}>
      <Breadcrumb itemsProps={generateBreadCrumbRoutes(location.pathname, breadcrumbCustomLabels, navigate, null, null)} />
      <Box>
        <HStack>
          <Text data-testid='title-automation-rules-page' color='#292D36' as='h1' textStyle='h1' mb='8px'>
            {customText.AuthorizationResults.title}
          </Text>
        </HStack>
      </Box>
      <Box>
        <AuthorizationAccordion data={data} isPatchingRules={isPatchingRules} handleReAuthorize={handleReAuthorize} />
      </Box>
    </Box>
  );
}

export default AuthorizationResults;
