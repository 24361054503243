import './styled/fonts';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
// eslint-disable-next-line import/no-unresolved
import { PrivateRender } from '@react-ib-mf/style-guide-ui';
import { AccordionChakra as Accordion } from '@wow/accordion';
import { AlertChakra as Alert } from '@wow/alert';
import { BadgeChakra as Badge } from '@wow/badge';
import { BreadcrumbChakra as Breadcrumb } from '@wow/breadcrumb';
import { ButtonChakra as Button } from '@wow/button';
import { CheckboxChakra as Checkbox } from '@wow/checkbox';
import { CloseButtonChakra as CloseButton } from '@wow/close-button';
import { ModalChakra as Modal } from '@wow/modal';
import { SkeletonChakra as Skeleton } from '@wow/progress-indicator';
import { TabsChakra as Tabs } from '@wow/tabs';
import { themeChakra } from '@wow/themes';
import { TooltipChakra as Tooltip } from '@wow/tooltip';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';

const { ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  breakpoints: { ...themeChakra.breakpoints },
  colors: { ...themeChakra.colors },
  layerStyles: { ...themeChakra.layerStyles },
  styles: { ...themeChakra.styles },
  textStyles: { ...themeChakra.textStyles },
  space: { ...themeChakra.space },
  sizes: { ...themeChakra.sizes },
  components: {
    Accordion,
    Alert,
    Badge,
    Breadcrumb,
    Button,
    Modal,
    CloseButton,
    Checkbox,
    Skeleton,
    Tooltip,
    Tabs,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <App />
          <ToastContainer />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
