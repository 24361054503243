/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
import { Button, Tab, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { BottomNav } from '@components/BottomNav/BottomNav.component';
import { RuleCardContainer } from '@components/RuleCard/RuleCardContainer.component';
import { useAuthorizeRules } from '@hooks';
import { IAuthorizationResponse, ISubscriptionRulesTabs } from '@interfaces';
import { TabsBadge } from '@wow/tabs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import customText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';

export function SubscriptionRulesTabs({
  data,
  isLoading = false,
  fetchRules,
  createRule,
  isLoadingPending = false,
  fetchPendingRules,
  screenErrorRules,
  screenErrorPending,
}: ISubscriptionRulesTabs) {
  const { tabActive, setTabActive, countRuleSelected, setShowAuthErrorScreen, selectedRulesToAuthorize, showErrorScreen } = useContext(
    AppContext,
  ) as any;

  const navigate = useNavigate();
  const { isPatchingRules, authorizeData } = useAuthorizeRules(setShowAuthErrorScreen);
  const [showBottomNav, setShowBottomNav] = useState(false);

  const leftContent = (
    <Text fontSize='14px' color='#2F3034'>
      {`${countRuleSelected} ${
        countRuleSelected > 1
          ? customText.subscriptionRuleManagement.bottomNav.labelLeftMore
          : customText.subscriptionRuleManagement.bottomNav.labelLeftOne
      }`}
    </Text>
  );

  useEffect(() => {
    if (selectedRulesToAuthorize.length > 0) {
      setShowBottomNav(true);
    }
  }, [selectedRulesToAuthorize]);

  const handleAuthorize = async () => {
    if (selectedRulesToAuthorize.length > 0) {
      try {
        const authorizationResult: IAuthorizationResponse = await authorizeData(selectedRulesToAuthorize);
        navigate('/reglas-automaticas/resultado', { state: { authorizationResult } });
        setShowBottomNav(false);
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  const rightContent = (
    <Button
      size='md'
      isDisabled={selectedRulesToAuthorize.length === 0 || isPatchingRules}
      isLoading={isPatchingRules}
      loadingText='Autorizando...'
      onClick={handleAuthorize}
    >
      {customText.subscriptionRuleManagement.bottomNav.buttonAuthorize}
    </Button>
  );

  const handleTabRulesClick = () => {
    setTabActive('rules');
  };

  const handleTabAuthorizationClick = () => {
    setTabActive('authorizations');
  };

  return (
    <>
      <Tabs mb={tabActive === 'authorizations' && countRuleSelected > 0 ? '80px' : '0px'} id='rules'>
        <TabList>
          <Tab onClick={handleTabRulesClick} data-id='tab-0'>
            {customText.tabs.rules.title}
          </Tab>

          <Tab onClick={handleTabAuthorizationClick} data-id='tab-1'>
            {customText.tabs.authorizations.title}
            {!isLoadingPending && !showErrorScreen?.pending && data?.pending && data?.pending?.items?.length > 0 && (
              <TabsBadge meta metaLabel={data?.pending && data?.pending?.items?.length.toString()} />
            )}
          </Tab>
        </TabList>

        <TabPanels>
          <RuleCardContainer
            type='rule'
            isLoadingTab={isLoading}
            data={data?.rules}
            fetchRules={fetchRules}
            createRule={createRule}
            showErrorScreen={screenErrorRules}
          />
          <RuleCardContainer
            type='authorization'
            isLoadingTab={isLoadingPending}
            data={data?.pending}
            fetchRules={fetchPendingRules}
            createRule={createRule}
            showErrorScreen={screenErrorPending}
          />
        </TabPanels>
      </Tabs>

      {tabActive === 'authorizations' && showBottomNav && selectedRulesToAuthorize.length > 0 && (
        <BottomNav leftContent={leftContent} rightContent={rightContent} isExpanded />
      )}
    </>
  );
}
