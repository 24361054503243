/* eslint-disable camelcase */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const dataLayer: any;

interface IAnalyticsEvent {
  [key: string]: string;
}

export const pushAnalyticsEvent = (args: IAnalyticsEvent) => dataLayer?.push(args);

export const pushAnalyticsErrorEvent = (service: string) =>
  pushAnalyticsEvent({
    event: 'impression_modal',
    content_group: 'Home',
    modal_name: `Error de Servicio - ${service}`,
  });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const pushAnalyticsFromError = (error: unknown) => pushAnalyticsErrorEvent(error?.config?.url || 'unknown');
