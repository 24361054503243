/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */

import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Badge, Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { AuthorizationItemDetails, FullscreenLoading } from '@components';
import { IRuleItem, IRulesAuthorizationResponse, IRuleSubscriptionList } from '@interfaces';
import { AppContext } from '@providers/AppContextProvider';
import { IconButtonComponent } from '@wow/button';
import { themeDefault } from '@wow/themes';
import React, { useContext } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import customText from '../../lang/es.json';

interface AuthorizationAccordionProps {
  data: IRulesAuthorizationResponse;
  isPatchingRules: boolean;
  handleReAuthorize: (itemsToAuthorize: IRuleSubscriptionList[]) => Promise<void>;
}

interface AuthorizationAccordionSectionProps {
  orders: IRuleItem[];
  idPrefix: string;
  badgeVariant: string;
  badgeText: string;
  mainText: string;
  secondaryText?: string | null;
  button: ((item: IRuleSubscriptionList[]) => React.ReactNode) | React.ReactNode;
  ariaLabelPrefix: string;
}

const AuthorizationAccordionSection: React.FC<AuthorizationAccordionSectionProps> = ({
  orders,
  idPrefix,
  badgeVariant,
  badgeText,
  mainText,
  secondaryText,
  button,
  ariaLabelPrefix,
}) => {
  const { colors } = themeDefault;

  return (
    orders?.length > 0 && (
      <Accordion allowMultiple defaultIndex={[]}>
        <AccordionItem id={`${idPrefix}-item`} border='solid 1px #B2B7C4'>
          {({ isExpanded }) => (
            <>
              {/* Accordion Button (Header) */}
              <AccordionButton height='unset' padding='unset'>
                <Box width='100%' p='17.5px 12px'>
                  <HStack justifyContent='space-between'>
                    <HStack spacing='16px'>
                      <Badge variant={badgeVariant}>{badgeText}</Badge>
                      <Box display='flex' alignItems='flex-start' flexDirection='column'>
                        <Text textStyle='titleMdBold' color={colors.secondary[700]}>
                          {mainText}
                        </Text>
                        {secondaryText && (
                          <Text textStyle='bodyMd' color={colors.secondary[900]}>
                            {secondaryText}
                          </Text>
                        )}
                      </Box>
                    </HStack>
                    <HStack spacing='24px'>
                      {typeof button === 'function' ? button(orders) : button}
                      <IconButtonComponent
                        aria-label={`${ariaLabelPrefix}`}
                        icon={isExpanded ? <ChevronUp /> : <ChevronDown />}
                        size='i-sm'
                        variant='icon-outline'
                      />
                    </HStack>
                  </HStack>
                </Box>
              </AccordionButton>

              {/* Accordion Panel (Content) */}
              <AccordionPanel padding='unset'>
                {orders.map(item => (
                  <AuthorizationItemDetails key={item.payload.id} data={item} />
                ))}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    )
  );
};

export const AuthorizationAccordion: React.FC<AuthorizationAccordionProps> = ({ data, isPatchingRules, handleReAuthorize }) => {
  const authorizedOrders = data?.authorized;
  const notAuthorizedOrders = data?.unauthorized;
  const navigate = useNavigate();
  const { setRulesToAuthorize } = useContext(AppContext) as { setRulesToAuthorize: (rules: string[]) => void };

  const getText = (type: 'authorized' | 'notAuthorized', count: number) => {
    const isPlural = count !== 1;
    const textType = isPlural ? 'plural' : 'singular';
    const mainTextTemplate = customText.AuthorizationAccordion[type][textType].mainText;
    const { secondaryText } = customText.AuthorizationAccordion[type][textType];

    const mainText = mainTextTemplate?.replace('{{count}}', count?.toString());

    return { mainText, secondaryText };
  };

  const handleReturnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setRulesToAuthorize([]);
    navigate('/reglas-automaticas/');
  };

  const authorizedTexts = getText('authorized', authorizedOrders?.length);
  const notAuthorizedTexts = getText('notAuthorized', notAuthorizedOrders?.length);

  return (
    <>
      {isPatchingRules && (
        <FullscreenLoading
          loading={isPatchingRules}
          loadingTitle1={customText.AuthorizationAccordion.fullScreenLoader.mainLoadingText}
          loadingSubtitle1={customText.AuthorizationAccordion.fullScreenLoader.secondaryLoadingText}
        />
      )}
      <VStack align='stretch' width='100%' spacing='16px'>
        {/* Render Authorized Orders Section */}
        <AuthorizationAccordionSection
          orders={authorizedOrders}
          idPrefix='authorized'
          badgeVariant='success-light'
          badgeText={customText.AuthorizationAccordion.badgeText.success}
          mainText={authorizedTexts.mainText}
          secondaryText={authorizedTexts.secondaryText}
          button={
            <Button variant='primary-text' size='lg' onClick={handleReturnClick}>
              {customText.AuthorizationAccordion.authorized.buttonText}
            </Button>
          }
          ariaLabelPrefix='toggle-authorized'
        />

        {/* Render Not Authorized Orders Section */}
        <AuthorizationAccordionSection
          orders={notAuthorizedOrders}
          idPrefix='notauthorized'
          badgeVariant='error-dark'
          badgeText={customText.AuthorizationAccordion.badgeText.error}
          mainText={notAuthorizedTexts.mainText}
          secondaryText={null}
          button={() => (
            <Button
              variant='primary-dark'
              size='lg'
              isLoading={isPatchingRules}
              loadingText='Autorizando...'
              onClick={e => {
                e.stopPropagation();
                handleReAuthorize(notAuthorizedOrders);
              }}
            >
              {customText.AuthorizationAccordion.notAuthorized.buttonText}
            </Button>
          )}
          ariaLabelPrefix='toggle-unauthorized'
        />
      </VStack>
    </>
  );
};
