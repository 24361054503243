/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import { useToast } from '@chakra-ui/react';
import { AuthorizeRequest } from '@interfaces';
import { postAuthorizeRules } from '@services/api';
import { ToastComponent } from '@wow/toast';
import { useCallback, useRef, useState } from 'react';

import customText from '../lang/es.json';

export function useAuthorizeRules(setShowErrorScreen: (data: boolean) => void, onSuccess?: () => void) {
  const [isPatchingRules, setIsPatchingRules] = useState(false);
  const authorizeDataRef = useRef(null);
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
    isClosable: true,
    status: 'error',
  });

  const getErrorMessage = useCallback((ruleIds, attempts) => {
    const isPlural = ruleIds.length > 1;
    const messageKey = isPlural ? 'plural' : 'singular';

    if (attempts < 2) {
      return customText.AuthorizationResults.toast.error.firstAttemptText[messageKey];
    }
    return customText.AuthorizationResults.toast.error.secondAttemptText[messageKey];
  }, []);

  const showErrorToast = useCallback(
    (error, ruleIds, attempts) => {
      const errorMessage = getErrorMessage(ruleIds, attempts);
      if (attempts < 2) {
        const handleRetry = async () => {
          try {
            await authorizeDataRef.current(ruleIds, attempts);
          } catch (e: any) {
            console.error(e);
          }
        };

        toast({
          status: 'error',
          duration: 4000,
          render: props => (
            <ToastComponent
              {...props}
              description={errorMessage}
              isCtaLabel
              ctaLabel={customText.AuthorizationResults.toast.error.retryButton}
              ctaOnClick={() => {
                props.onClose();
                handleRetry();
              }}
            />
          ),
        });
      } else {
        toast({
          status: 'error',
          duration: 4000,
          render: props => <ToastComponent {...props} description={errorMessage} />,
        });
      }
    },
    [toast, getErrorMessage],
  );

  const authorizeData = useCallback(
    async (ruleIds: AuthorizeRequest[], attempts = 0) => {
      try {
        setIsPatchingRules(true);
        const response = await postAuthorizeRules(ruleIds);

        if (response.status === 200) {
          if (onSuccess) {
            await onSuccess();
          }
          setShowErrorScreen(false);
          return response.data;
        }
        throw new Error('Failed to patch rules');
      } catch (error) {
        const newAttemptCount = attempts + 1;
        showErrorToast(error, ruleIds, newAttemptCount);
        setShowErrorScreen(true);
        throw error;
      } finally {
        setIsPatchingRules(false);
      }
    },
    [onSuccess, setShowErrorScreen, showErrorToast],
  );

  authorizeDataRef.current = authorizeData;

  return {
    isPatchingRules,
    authorizeData: ruleIds => authorizeData(ruleIds),
  };
}
