/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import noPendingStateIcon from '@assets/like.svg';
import emptyStateIcon from '@assets/rocket.svg';
import errorStateIcon from '@assets/underConstruction.svg';
import { Box, Checkbox, TabPanel } from '@chakra-ui/react';
import { CardSkeleton } from '@components/CardSkeleton/CardSkeleton.component';
import { StateContentScreen } from '@components/StateContentScreen/StateContentScreen.component';
import { IPagination, IRuleCardContainer } from '@interfaces';
import { TablePaginationComponent } from '@wow/table-pagination';
import { themeChakra } from '@wow/themes';
import { useCallback, useContext, useEffect, useState } from 'react';

import customText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';
import { RuleCard } from './RuleCard.component';

export function RuleCardContainer({ type, isLoadingTab = false, data, fetchRules, createRule, showErrorScreen }: IRuleCardContainer) {
  const { hasCreateSubcriptionPermission, setCountRuleSelected, selectedRulesToAuthorize, setRulesToAuthorize } = useContext(
    AppContext,
  ) as any;
  const [selectedAll, setSelectedAll] = useState(false);

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 0,
    elementsPerPage: 15,
    totalElements: 0,
    totalPages: 0,
  });

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);

  useEffect(() => {
    if (data) {
      setPagination({
        currentPage: page,
        elementsPerPage: size,
        totalElements: data?.totalRows,
        totalPages: data?.totalPages,
      });
    }
  }, [data, page, size]);

  const onChangePagination = useCallback(() => {
    fetchRules({
      pages: page + 1, // Cambia a +1 si tu API usa índices de página basados en 1
      limit: size,
      type: type === 'rule' ? 'all' : 'onlyPendingRules',
    });
  }, [fetchRules, page, size, type]);

  useEffect(() => {
    onChangePagination();
  }, [page, size]);

  const handleSelectAll = () => {
    if (selectedAll) {
      setRulesToAuthorize([]);
    } else {
      setRulesToAuthorize(data?.items?.map(item => item.payload.id));
    }
    setSelectedAll(!selectedAll);
  };

  const handleCheckboxChange = (id: string) => {
    if (selectedRulesToAuthorize.includes(id)) {
      setRulesToAuthorize(selectedRulesToAuthorize.filter(itemId => itemId !== id));
    } else {
      setRulesToAuthorize([...selectedRulesToAuthorize, id]);
    }
  };

  useEffect(() => {
    setCountRuleSelected(selectedRulesToAuthorize.length);
  }, [selectedRulesToAuthorize]);

  useEffect(() => {
    setSelectedAll(selectedRulesToAuthorize.length === data?.items?.length);
  }, [selectedRulesToAuthorize, data]);

  const handleFetchRules = () => {
    fetchRules({ pages: 1, limit: 15, type: type === 'rule' ? 'all' : 'onlyPendingRules' });
  };

  return (
    <TabPanel fontFamily='Inter'>
      {!isLoadingTab && !showErrorScreen && data?.items && data?.items.length > 0 ? (
        <>
          {type === 'authorization' && (
            <Box
              borderRadius='12px'
              border='1px solid #BEC6DC'
              padding='19px'
              gap='10px'
              mt='24px'
              display='flex'
              alignItems='center'
              height='68px'
            >
              <Checkbox isChecked={selectedAll} onChange={handleSelectAll} />
              <strong
                style={{
                  color: '#3B414E',
                  marginLeft: '30px',
                  fontFamily: themeChakra.textStyles.titleMdBold.fontFamily,
                }}
              >
                {customText.rulesCardContainer.selectedAllTitle}
              </strong>
            </Box>
          )}

          {data?.items?.map((item, index) => (
            <RuleCard
              key={item.payload.id}
              item={item}
              isChecked={selectedRulesToAuthorize.includes(item.payload.id)}
              onCheckboxChange={handleCheckboxChange}
              type={type}
              fetchRules={fetchRules}
              itemId={`rule-item-${index}`}
            />
          ))}

          <Box mt='15px'>
            <TablePaginationComponent
              totalPages={pagination.totalPages || 1}
              currentIndex={pagination.currentPage}
              totalRecords={pagination.totalElements}
              perPage={[15, 30, 45]}
              disabledPerPage={pagination.totalElements <= 15}
              currentPerPage={pagination.elementsPerPage}
              onNext={() => {
                setPage(page + 1);
              }}
              onPrev={() => {
                setPage(page - 1);
              }}
              onFirst={() => setPage(0)}
              onLast={() => setPage(pagination.totalPages - 1)}
              onJump={value => {
                setPage(Number(value) - 1);
              }}
              onPerPageChange={value => {
                setSize(Number(value));
                setPage(0);
                fetchRules({ pages: 1, limit: value, type: type === 'rule' ? 'all' : 'onlyPendingRules' });
              }}
            />
          </Box>
        </>
      ) : !isLoadingTab && !showErrorScreen && data?.items && data?.items?.length === 0 && type === 'rule' ? (
        <StateContentScreen
          IconState={emptyStateIcon}
          titleText={
            hasCreateSubcriptionPermission
              ? customText.emptyStateScreen.title
              : customText.subscriptionRuleManagement.emptyStateNoAuthRules.title
          }
          bodyText={
            hasCreateSubcriptionPermission
              ? customText.emptyStateScreen.description
              : customText.subscriptionRuleManagement.emptyStateNoAuthRules.description
          }
          buttonText={hasCreateSubcriptionPermission && customText.emptyStateScreen.buttonText}
          buttonAction={createRule}
          data-testid='empty-state-content'
        />
      ) : !isLoadingTab && !showErrorScreen && data?.items && data?.items?.length === 0 && type === 'authorization' ? (
        <StateContentScreen
          IconState={noPendingStateIcon}
          titleText={customText.subscriptionRuleManagement.emptyStateNoPendingRules.title}
          bodyText={customText.subscriptionRuleManagement.emptyStateNoPendingRules.description}
          data-testid='empty-state-content'
        />
      ) : (
        isLoadingTab && <CardSkeleton />
      )}
      {!isLoadingTab && showErrorScreen && (
        <StateContentScreen
          IconState={errorStateIcon}
          titleText={customText.subscriptionRuleManagement.emptyStateError.title}
          bodyText={customText.subscriptionRuleManagement.emptyStateError.description}
          buttonText={customText.subscriptionRuleManagement.emptyStateError.buttonText}
          buttonAction={handleFetchRules}
          data-testid='empty-state-content-error'
        />
      )}
    </TabPanel>
  );
}
