/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { useToast } from '@chakra-ui/react';
import { IGetRulesBanksResponse } from '@interfaces';
import { getBanks } from '@services/api';
import { ToastComponent } from '@wow/toast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import customText from '../lang/es.json';

export function useFetchBanks(setRuleBankData: (data: IGetRulesBanksResponse) => void) {
  const [isFetchingBanks, setIsFetchingBanks] = useState(false);
  const navigate = useNavigate();
  const toast = useToast({
    containerStyle: {
      marginLeft: '60px',
    },
  });

  const fetchBanksData = async () => {
    try {
      setIsFetchingBanks(true);
      const response = await getBanks();

      if (response.data) {
        setRuleBankData(response.data);
        navigate('/reglas-automaticas/crear-regla');
      }
    } catch (error) {
      showErrorToast();
    } finally {
      setIsFetchingBanks(false);
    }
  };

  const showErrorToast = () => {
    const toastId = toast({
      duration: 10000,
      position: 'bottom-left',
      status: 'error',
      description: customText.home.toast.error.getBanks,
      isClosable: true,

      render: (props: any) => (
        <ToastComponent
          {...props}
          isCtaLabel
          ctaLabel={customText.home.toast.error.retryButton}
          ctaOnClick={() => {
            toast.close(toastId);
            fetchBanksData();
          }}
        />
      ),
    });
  };

  return {
    isFetchingBanks,
    fetchBanksData,
  };
}
