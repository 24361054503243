/* eslint-disable import/prefer-default-export */
import { BreadcrumbComponent } from '@wow/breadcrumb';

interface Props {
  itemsProps: Items[];
}

interface Items {
  key: string;
  id: string;
  link: string;
  label: string;
  isCurrentPage?: boolean;
}

export function Breadcrumb({ itemsProps }: Props) {
  return <BreadcrumbComponent items={itemsProps} marginBottom='8px' />;
}
