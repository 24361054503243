/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Checkbox, HStack, Icon, Link, Text, Tooltip } from '@chakra-ui/react';
import { BottomNav, Breadcrumb, CloseModal, FullscreenLoading, StateModal, SubscriptionRuleSummary } from '@components';
import { IRuleSubscriptionList } from '@interfaces';
import { postAuthorizeRules, postCreateRule } from '@services/api';
import { convertDayNumbers, convertTimeToHour, generateBreadCrumbRoutes } from '@utils';
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { themeChakra } from '@wow/themes';
import { useContext, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';

import customText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';

function SubscriptionRuleStepTwoPage() {
  const {
    isAgreementRuleChecked,
    setIsAgreementRuleChecked,
    hasAuthPermission,
    hasCreateSubcriptionPermission,
    selectedBank,
    selectedAccount,
    selectedAccountBrokerage,
    selectedFund,
    selectedSchedule,
    amountValue,
    selectedDays,
    isFormWithValues,
    resetSubscriptionRuleData,
    isValidFormData,
    setEnableEditForm,
    profileRiskData,
  } = useContext(AppContext) as any;

  const selectedData = {
    selectedBank,
    selectedAccount,
    selectedAccountBrokerage,
    selectedFund,
    selectedDays,
    selectedSchedule,
    amountValue,
  };

  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [modalGoBackVisible, setModalGoBackVisible] = useState(false);
  const [modalCreatedRule, setModalCreatedRule] = useState(false);
  const [modalCreatedRuleNoAutho, setModalCreatedRuleNoAutho] = useState(false);
  const [modalErrorCreateRule, setModalErrorCreateRule] = useState(false);
  const [modalAuthorization, setModalAuthorization] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const [isPostSubscription, setIsPostSubscription] = useState(false);
  const [isAuthorizingLoading, setIsAuthorizingLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [authorizeRetry, setAuthorizeRetry] = useState(0);
  const [modalErrorAuthorization, setModalErrorAuthorization] = useState(false);
  const [newRuleCreated, setNewRuleCreated] = useState<IRuleSubscriptionList | null>(null);

  const breadcrumbLabels = ['Inicio', 'Reglas automáticas', 'Crear regla', 'Resumen'];

  const handleCheckboxChange = () => {
    setIsAgreementRuleChecked(!isAgreementRuleChecked);
  };

  const handleContinueGoBack = () => {
    resetSubscriptionRuleData();
    if (pendingNavigation) {
      navigate(pendingNavigation);
      setModalGoBackVisible(false);
      setPendingNavigation(null);
    } else {
      navigate('/reglas-automaticas');
    }
  };

  const createRule = async ruleData => {
    try {
      setIsPostSubscription(true);
      const response = await postCreateRule(ruleData);

      if (response.status === 200) {
        setNewRuleCreated(response.data);
        if (hasCreateSubcriptionPermission && hasAuthPermission) {
          setModalCreatedRule(true);
        } else {
          setModalCreatedRuleNoAutho(true);
        }
      }
    } catch (error) {
      setRetryCount(retryCount + 1);
      setModalErrorCreateRule(true);
    } finally {
      setIsPostSubscription(false);
    }
  };

  const handleCreatedRule = () => {
    setModalErrorCreateRule(false);
    createRule({
      timeOfDay: convertTimeToHour(selectedData.selectedSchedule.value),
      daysOfWeek: convertDayNumbers(selectedData.selectedDays),
      payload: {
        accountMinimumBalance: parseFloat(selectedData.amountValue),
        bank: {
          id: selectedData.selectedBank.data.id,
          configId: selectedData.selectedBank.data.configId,
        },
        account: {
          id: selectedData.selectedAccount.value,
        },
        fund: {
          id: Number(selectedData.selectedFund.value),
        },
        investorAccount: {
          investorNumber: selectedData.selectedAccountBrokerage.value,
        },
        checkContract: true,
      },
    });
  };

  const handleAuthorizeRule = async id => {
    setModalCreatedRule(false);
    setModalErrorAuthorization(false);
    setIsAuthorizingLoading(true);
    try {
      const response = await postAuthorizeRules(id);

      if (response.data) {
        setModalAuthorization(true);
      }
    } catch (error) {
      setAuthorizeRetry(authorizeRetry + 1);
      setModalErrorAuthorization(true);
    } finally {
      setIsAuthorizingLoading(false);
    }
  };

  const leftContent = (
    <Button
      size='md'
      variant='link'
      color='#037193'
      textDecoration='underline'
      fontWeight={700}
      textUnderlineOffset='3px'
      onClick={() => {
        setEnableEditForm(true);
        navigate('/reglas-automaticas/crear-regla');
      }}
      leftIcon={<Icon as={ChevronLeft} />}
    >
      {customText.steps.summaryRule.bottomNav.goBackButton}
    </Button>
  );

  const rightContent = (
    <Box display='flex' justifyContent='center' alignItems='center' gap='20px'>
      <Box fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontSize='14px'>
        {customText.steps.summaryRule.bottomNav.StepText}
      </Box>
      <Button
        size='md'
        variant='link'
        color='#2172DC'
        textDecoration='underline'
        fontWeight={700}
        textUnderlineOffset='3px'
        onClick={() => setModalGoBackVisible(true)}
      >
        {customText.steps.summaryRule.bottomNav.cancelButton}
      </Button>
      <Tooltip hasArrow label={customText.steps.summaryRule.bottomNav.tooltipNext} placement='left' isDisabled={isAgreementRuleChecked}>
        <Button size='md' isDisabled={!(isAgreementRuleChecked && isValidFormData)} onClick={() => handleCreatedRule()}>
          {customText.steps.summaryRule.bottomNav.nextStepButton}
        </Button>
      </Tooltip>
    </Box>
  );

  return (
    <Box ml={30} mr={30} mt={1} mb={90}>
      <Breadcrumb
        itemsProps={generateBreadCrumbRoutes(
          location.pathname,
          breadcrumbLabels,
          navigate,
          setModalGoBackVisible,
          setPendingNavigation,
          setEnableEditForm,
          isFormWithValues,
        )}
      />
      <Box>
        <HStack style={{ justifyContent: 'space-between' }}>
          <Text data-testid='title-subscription-automation-rules-page' color='#292D36' as='h1' textStyle='h1' fontSize='32px' mb='8px'>
            {customText.steps.summaryRule.title}
          </Text>
        </HStack>
        <Text data-testid='description-subscription-automation-rules-page' textStyle='bodyMd' color='#292D36'>
          {customText.steps.summaryRule.description}
        </Text>
      </Box>

      {isValidFormData && isAlertOpen && (
        <AlertComponent mt='18px' maxW='881px' id='alert-component' variant='information' onClose={() => setIsAlertOpen(false)}>
          <AlertTitle>{customText.steps.summaryRule.alert.info.title}</AlertTitle>
          <AlertDescription>{customText.steps.summaryRule.alert.info.description}</AlertDescription>
        </AlertComponent>
      )}
      {isValidFormData && <SubscriptionRuleSummary subscriptionData={selectedData} />}
      {isValidFormData && (
        <Box fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} padding='13px 5px'>
          <Checkbox marginRight='10px' onChange={handleCheckboxChange} isChecked={isAgreementRuleChecked} />
          {customText.steps.summaryRule.checkedSummary.acceptText}
          <Link
            href={selectedFund?.data?.managementRegulationUrl}
            isExternal
            color='#2172DC'
            textDecoration='underline'
            textUnderlineOffset='3px'
            download
          >
            {customText.steps.summaryRule.checkedSummary.acceptLink}
          </Link>
        </Box>
      )}

      {isValidFormData && <BottomNav leftContent={leftContent} rightContent={rightContent} isExpanded />}
      <CloseModal isOpen={modalGoBackVisible} onContinue={handleContinueGoBack} onClose={() => setModalGoBackVisible(false)} />

      <StateModal
        headerState='success'
        headerText={customText.steps.summaryRule.modal.createNoAuth.title}
        continueButtonText={customText.steps.summaryRule.modal.createNoAuth.acceptButton}
        cancelButtonText=''
        isContinueButtonVisible
        isCancelButtonVisible={false}
        isOpen={modalCreatedRuleNoAutho}
        onContinue={handleContinueGoBack}
        onClose={() => {
          handleContinueGoBack();
          setModalCreatedRuleNoAutho(false);
        }}
        isCentered
        size='md'
      >
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' sx={{ textWrap: 'auto' }}>
          {customText.steps.summaryRule.modal.createNoAuth.descSecondStart}
          <b>{customText.steps.summaryRule.modal.createNoAuth.descSecondRules}</b>
          {customText.steps.summaryRule.modal.createNoAuth.descSecondWith}
          <b>{customText.steps.summaryRule.modal.createNoAuth.descSecondEnd}</b>
        </Text>
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' fontSize='12px' color='#037193' sx={{ textWrap: 'auto' }}>
          {customText.steps.summaryRule.modal.createNoAuth.descSecond}
        </Text>
      </StateModal>

      <StateModal
        headerState='info'
        headerText={customText.steps.summaryRule.modal.create.title}
        continueButtonText={customText.steps.summaryRule.modal.create.acceptButton}
        cancelButtonText={customText.steps.summaryRule.modal.create.cancelButton}
        isContinueButtonVisible
        isCancelButtonVisible
        isOpen={modalCreatedRule}
        onContinue={() => handleAuthorizeRule([newRuleCreated.payload.id])}
        onClose={() => {
          handleContinueGoBack();
          setModalCreatedRule(false);
        }}
        isCentered
        size='md'
      >
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' sx={{ textWrap: 'auto' }}>
          {customText.steps.summaryRule.modal.create.descMain}
        </Text>
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' sx={{ textWrap: 'auto' }}>
          {customText.steps.summaryRule.modal.create.descSecondStart}
          <b>{customText.steps.summaryRule.modal.create.descSecondRules}</b>
          {customText.steps.summaryRule.modal.create.descSecondWith}
          <b>{customText.steps.summaryRule.modal.create.descSecondEnd}</b>
        </Text>
      </StateModal>

      <StateModal
        headerState='error'
        headerText={customText.steps.summaryRule.modal.error.title}
        continueButtonText={
          retryCount < 3 ? customText.steps.summaryRule.modal.error.retryButton : customText.steps.summaryRule.modal.error.acceptButtonTwo
        }
        cancelButtonText={customText.steps.summaryRule.modal.error.closeButtonOne}
        isContinueButtonVisible
        isCancelButtonVisible={false}
        isOpen={modalErrorCreateRule}
        isCloseButtonVisible={retryCount < 3}
        onContinue={() => {
          if (retryCount < 3) {
            handleCreatedRule();
          } else {
            handleContinueGoBack();
          }
        }}
        onClose={() => {
          setModalErrorCreateRule(false);
        }}
        isCentered
        size='md'
      >
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' sx={{ textWrap: 'auto' }}>
          {retryCount < 3 ? customText.steps.summaryRule.modal.error.descErrorOne : customText.steps.summaryRule.modal.error.descErrorTwo}
        </Text>
      </StateModal>

      <StateModal
        headerState='success'
        headerText='Autorizaste la regla automática con éxito'
        continueButtonText='Entendido'
        cancelButtonText=''
        isContinueButtonVisible
        isCancelButtonVisible={false}
        isOpen={modalAuthorization}
        onContinue={handleContinueGoBack}
        onClose={() => {
          handleContinueGoBack();
          setModalCreatedRuleNoAutho(false);
          setIsAgreementRuleChecked(false);
        }}
        isCentered
        size='md'
      >
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' sx={{ textWrap: 'auto' }}>
          {profileRiskData && profileRiskData?.authorizersAmount < 2 ? (
            <>
              {customText.steps.summaryRule.modal.Authorization.desOneAuthorizer1}
              <b>{customText.steps.summaryRule.modal.Authorization.desOneAuthorizer2}</b>
            </>
          ) : (
            customText.steps.summaryRule.modal.Authorization.desTwoAuthorizers
          )}
        </Text>
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' fontSize='12px' color='#037193' sx={{ textWrap: 'auto' }}>
          {customText.steps.summaryRule.modal.Authorization.desFooterAuthorizer}
        </Text>
      </StateModal>

      <StateModal
        headerState='error'
        headerText={customText.steps.summaryRule.modal.error.titleAuthorize}
        continueButtonText={
          authorizeRetry < 3
            ? customText.steps.summaryRule.modal.error.retryButton
            : customText.steps.summaryRule.modal.error.acceptButtonTwo
        }
        cancelButtonText=''
        isContinueButtonVisible
        isCancelButtonVisible={false}
        isOpen={modalErrorAuthorization}
        isCloseButtonVisible={authorizeRetry < 3}
        onContinue={() => {
          if (authorizeRetry < 3) {
            handleAuthorizeRule([newRuleCreated.payload.id]);
          } else {
            handleContinueGoBack();
            setIsAgreementRuleChecked(false);
          }
        }}
        onClose={() => {
          setModalErrorAuthorization(false);
          handleContinueGoBack();
          setIsAgreementRuleChecked(false);
        }}
        isCentered
        size='md'
      >
        <Text textAlign='center' whiteSpace='break-spaces' textStyle='bodyMd' sx={{ textWrap: 'auto' }}>
          {authorizeRetry < 3 ? (
            customText.steps.summaryRule.modal.error.descErrorAuthorize
          ) : (
            <>
              {customText.steps.summaryRule.modal.error.descErrorAuthorize2}
              <b>{customText.steps.summaryRule.modal.error.descErrorAuthorize3}</b>
            </>
          )}
        </Text>
      </StateModal>
      {(isPostSubscription || isAuthorizingLoading) && (
        <FullscreenLoading
          loading={isPostSubscription || isAuthorizingLoading}
          loadingTitle1={customText.fullLoadingScreen.title}
          loadingSubtitle1={customText.fullLoadingScreen.description}
        />
      )}
    </Box>
  );
}

export default SubscriptionRuleStepTwoPage;
