/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */

import { Box, Grid, Text, VStack } from '@chakra-ui/react';
import { IRuleItem } from '@interfaces';
import { formatAccountType, formatMoney, formatRuleHour, formatWeekdays } from '@utils';
import { themeDefault } from '@wow/themes';
import styled from 'styled-components';

interface AuthorizationItemDetailsProps {
  data: IRuleItem;
}

const { colors } = themeDefault;

interface DetailItemProps {
  title: string;
  children: React.ReactNode;
}

const titleTextProps = {
  textStyle: 'titleMdBold',
  color: colors.secondary[700],
};

const overlineTextProps = {
  textStyle: 'overlineLg',
  color: colors.secondary[500],
};

const bodyTextProps = {
  textStyle: 'bodyMd',
  color: colors.secondary[900],
};

export const StyledWrapper = styled(Box)`
  border-top: 1px solid ${colors.secondary[100]};
  padding: 12px;
`;

const DetailItem: React.FC<DetailItemProps> = ({ title, children }: DetailItemProps) => (
  <VStack align='start' maxWidth='280px' spacing='3px'>
    <Text {...titleTextProps}>{title}</Text>
    {children}
  </VStack>
);

export const AuthorizationItemDetails: React.FC<AuthorizationItemDetailsProps> = ({ data }) => {
  const { daysOfWeek, timeOfDay, payload } = data;
  const formattedDays = formatWeekdays(daysOfWeek);
  const formattedHour = formatRuleHour(timeOfDay);

  return (
    <StyledWrapper>
      <Grid templateColumns='repeat(5, 1fr)' gap={6}>
        <DetailItem title='Se debita desde'>
          <Text {...overlineTextProps}>{payload.bank.name}</Text>
          <Text>{`${formatAccountType(payload?.account.type)}  | ${payload.account.currencyCode} | ${payload.account.id}`}</Text>
        </DetailItem>

        <DetailItem title='Se suscribe a'>
          <Text {...bodyTextProps}>{`${payload.fund.name} ${payload.fund.fundClass && `- Clase ${payload.fund.fundClass} `}${
            payload.fund.type && `${payload.fund.type}`
          }`}</Text>
        </DetailItem>
        <DetailItem title='Condición de la regla'>
          <Text textStyle='bodyMd' color={colors.secondary[900]}>{`Se mantienen ${formatMoney({
            value: payload.accountMinimumBalance,
          })} en la cuenta y se suscribe el monto restante`}</Text>
        </DetailItem>

        <DetailItem title='Se repite'>
          <Text {...bodyTextProps}>{`Todos los ${formattedDays} a las ${formattedHour}`}</Text>
        </DetailItem>

        <DetailItem title='Autorizaciones'>
          <Text {...bodyTextProps}>{`${payload.operatorsBasedInActions?.filter(rule => rule.action === 'AUTHORIZE')?.length} de ${
            payload.totalAuthorizers
          }`}</Text>
        </DetailItem>
      </Grid>
    </StyledWrapper>
  );
};
