/* eslint-disable no-nested-ternary */
export const formatSummaryAccountDetails = (values: string) => {
  const items = values ? values.split('|').map(item => item.trim()) : [];

  const accountDetail = {
    type:
      items[0] && items[0].toLowerCase() === 'cc'
        ? 'Cuenta corriente'
        : items[0] && items[0].toLowerCase() === 'ca'
        ? 'Caja de ahorro'
        : '',
    currency: items[1] || '',
    accountNumber: items[2] ? items[2].replace(/-/g, '') : '',
  };

  return `${accountDetail.type} | ${accountDetail.currency} | ${accountDetail.accountNumber}`;
};

export const formatDaysOrder = (days: string[]) => {
  const orderedDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'];
  let frequencyLabel = '';

  if (days.length === 5) {
    frequencyLabel = ' todos los días';
  } else if (days.length === 1) {
    frequencyLabel = ` todos los ${days[0].toLowerCase()}`;
  } else if (days.length > 1) {
    const sortedDays = [...days].sort((a, b) => orderedDays.indexOf(a) - orderedDays.indexOf(b));
    const formattedDays = sortedDays.join(', ').replace(/, ([^,]*)$/, ' y $1');
    frequencyLabel = ` todos los ${formattedDays.toLowerCase()}`;
  }

  return frequencyLabel;
};

export const formatBankName = (bank: string) => {
  return bank?.replace(/^Banco\s+/, '');
};

export type Risk = 'CONSERVADOR' | 'MODERADO' | 'AGRESIVO';

export const riskDictionary = {
  CONSERVADOR: 1,
  MODERADO: 2,
  AGRESIVO: 3,
};
export const validateRisk = (investorRisk, fundRisk) => {
  return riskDictionary[investorRisk as Risk] < riskDictionary[fundRisk as Risk];
};
