/* eslint-disable import/no-unresolved */
import { WithPermissions } from '@components';
import ErrorPageContent from '@components/ErrorPageContent/ErrorPageContent.component';
import { Home } from '@pages';
import AuthorizationResults from '@pages/AuthorizationResults/AuthorizationResults.page';
import SubscriptionRuleStepOnePage from '@pages/SubscriptionSteps/SubscriptionRuleStepOne.page';
import SubscriptionRuleStepTwoPage from '@pages/SubscriptionSteps/SubscriptionRuleStepTwo.page';
import { permissionDictionary } from '@utils';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AppContainer from './layouts/AppContainer';
import AppContextProvider from './providers/AppContextProvider';

function App() {
  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path='/' element={<AppContainer />} />
          <Route
            path='/reglas-automaticas'
            element={
              <WithPermissions permission={[permissionDictionary.authorizations, permissionDictionary.CreateSubscription]} showErrorPage>
                <Home />
              </WithPermissions>
            }
          />
          <Route
            path='/reglas-automaticas/crear-regla'
            element={
              <WithPermissions permission={[permissionDictionary.CreateSubscription]} showErrorPage>
                <SubscriptionRuleStepOnePage />
              </WithPermissions>
            }
          />
          <Route
            path='/reglas-automaticas/crear-regla/resumen'
            element={
              <WithPermissions permission={[permissionDictionary.authorizations, permissionDictionary.CreateSubscription]} showErrorPage>
                <SubscriptionRuleStepTwoPage />
              </WithPermissions>
            }
          />
          <Route
            path='/reglas-automaticas/resultado'
            element={
              <WithPermissions permission={[permissionDictionary.authorizations]} showErrorPage>
                <AuthorizationResults />
              </WithPermissions>
            }
          />
          <Route path='*' element={<ErrorPageContent />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
