/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { BankIcon, InversionesIcon } from '@assets';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { formatBankName, formatDaysOrder, formatMoney, formatSummaryAccountDetails } from '@utils';
import { themeChakra } from '@wow/themes';
import { Calendar, Repeat } from 'react-feather';

import customText from '../../lang/es.json';

export function SubscriptionRuleSummary({ subscriptionData }: any) {
  return (
    <VStack maxW='578px' alignItems='stretch' mt='5px' gap={0}>
      <HStack gap='11px' justifyContent='left' borderBottom='1px solid #C7C6CA' padding='11px' mt='0px !important'>
        <BankIcon />
        <VStack alignItems='start' gap={0}>
          <Box fontSize='18px' fontFamily={themeChakra.textStyles.h6.fontFamily} fontWeight={700}>
            {customText.steps.summaryRule.section.bankTitle}
          </Box>
          <HStack fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontSize='14px' alignItems='start' mt='-3px !important'>
            <Box mt='0px !important'>
              {`${customText.steps.summaryRule.section.bankLabel} ${formatBankName(subscriptionData.selectedBank?.label)}`} |
              {` ${formatSummaryAccountDetails(subscriptionData?.selectedAccount?.label)}`}
            </Box>
          </HStack>
        </VStack>
      </HStack>
      <HStack gap='11px' justifyContent='left' borderBottom='1px solid #C7C6CA' padding='11px' mt='0px !important'>
        <InversionesIcon />
        <VStack alignItems='start' gap={0}>
          <Box fontSize='18px' fontFamily={themeChakra.textStyles.h6.fontFamily} fontWeight={700} mt='0px !important'>
            {customText.steps.summaryRule.section.fundTitle}
          </Box>
          <HStack fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontSize='14px' alignItems='start' mt='-3px !important'>
            <Box mt='0px !important'>
              {`${customText.steps.summaryRule.section.fundLabel} ${subscriptionData.selectedFund?.label}.`}
              {` ${customText.steps.summaryRule.section.fundTime} ${subscriptionData.selectedFund?.period} `}
            </Box>
          </HStack>
        </VStack>
      </HStack>

      <HStack gap='11px' justifyContent='left' borderBottom='1px solid #C7C6CA' padding='11px' mt='0px !important'>
        <Repeat color='#565E71' />
        <VStack alignItems='start' gap={0}>
          <Box fontSize='18px' fontFamily={themeChakra.textStyles.h6.fontFamily} fontWeight={700} mt='0px !important'>
            {customText.steps.summaryRule.section.amountTitle}
          </Box>
          <VStack fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontSize='14px' alignItems='start' gap={0} mt='0px !important'>
            <Box mt='0px !important'>{formatMoney({ value: subscriptionData?.amountValue })}</Box>
            <Box mt='-3px !important'>{customText.steps.summaryRule.section.amountDescription}</Box>
          </VStack>
        </VStack>
      </HStack>

      <HStack gap='11px' justifyContent='left' borderBottom='1px solid #C7C6CA' padding='11px' mt='0px !important'>
        <Calendar color='#565E71' />
        <VStack alignItems='start' gap={0}>
          <Box fontSize='18px' fontFamily={themeChakra.textStyles.h6.fontFamily} fontWeight={700} mt='0px !important'>
            {customText.steps.summaryRule.section.schduleTitle}
          </Box>
          <HStack fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontSize='14px' alignItems='start' mt='-3px !important'>
            <Box mt='0px !important'>
              {customText.steps.summaryRule.section.scheduleLabel + formatDaysOrder(subscriptionData?.selectedDays)}
              {` ${customText.steps.summaryRule.section.scheduleOnTime} ${subscriptionData?.selectedSchedule?.label}.`}
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
}
