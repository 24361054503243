/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { hasSomePermission, permissionDictionary } from '@utils';
import { createContext, useEffect, useMemo, useState } from 'react';
import {
  IErrorState,
  IFundResponse,
  IGetInvestConfig,
  IGetRulesBanksResponse,
  IOption,
  IProfileRiskResponse,
  IRulesData,
} from 'src/interfaces';

export const AppContext = createContext({});
export default function AppContextProvider({ children }: any) {
  const hasAuthPermission = hasSomePermission([permissionDictionary.authorizations]);
  const hasCreateSubcriptionPermission = hasSomePermission([permissionDictionary.CreateSubscription]);
  const [ruleBankData, setRuleBankData] = useState<IGetRulesBanksResponse>(null);
  const [rulesData, setRulesData] = useState<IRulesData>({ rules: null, pending: null });
  const [isAgreementRuleChecked, setIsAgreementRuleChecked] = useState<boolean>(false);
  const [selectedBank, setSelectedBank] = useState<IOption | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<IOption | null>(null);
  const [selectedAccountBrokerage, setSelectedAccountBrokerage] = useState<IOption | null>(null);
  const [selectedFund, setSelectedFund] = useState<IOption | null>(null);
  const [selectedSchedule, setSelectedSchedule] = useState<IOption | null>(null);
  const [amountValue, setAmountValue] = useState<string>('');
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [isFormWithValues, setIsFormWithValues] = useState<boolean>(false);
  const [isValidFormData, setIsValidFormData] = useState<boolean>(false);
  const [enableEditForm, setEnableEditForm] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState<'rules' | 'authorizations'>('rules');
  const [countRuleSelected, setCountRuleSelected] = useState<number>(0);
  const [showErrorScreen, setShowErrorScreen] = useState<IErrorState>({ rules: false, pending: false });
  const [selectedRulesToAuthorize, setRulesToAuthorize] = useState<string[]>([]);
  const [investConfig, setInvestConfig] = useState<IGetInvestConfig | null>(null);
  const [showAuthErrorScreen, setShowAuthErrorScreen] = useState<boolean>(false);
  const [ruleFundData, setRuleFundData] = useState<IFundResponse[] | null>(null);
  const [profileRiskData, setProfileRiskData] = useState<IProfileRiskResponse | null>(null);
  const [wizardCompleted, setWizardCompleted] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetSubscriptionRuleData = () => {
    setIsAgreementRuleChecked(false);
    setSelectedBank(null);
    setSelectedAccount(null);
    setSelectedAccountBrokerage(null);
    setSelectedFund(null);
    setSelectedSchedule(null);
    setAmountValue('');
    setSelectedDays([]);
    setRuleBankData(null);
    setRuleFundData(null);
    setProfileRiskData(null);
  };

  useEffect(() => {
    setIsFormWithValues(
      selectedBank !== null ||
        selectedAccount !== null ||
        selectedAccountBrokerage !== null ||
        selectedFund !== null ||
        amountValue.trim() !== '' ||
        selectedSchedule !== null ||
        selectedDays.length > 0,
    );
  }, [selectedBank, selectedAccount, selectedAccountBrokerage, selectedFund, selectedDays, selectedSchedule, amountValue]);

  useEffect(() => {
    setIsValidFormData(
      selectedBank !== null &&
        selectedAccount !== null &&
        selectedAccountBrokerage !== null &&
        selectedFund !== null &&
        amountValue.trim() !== '' &&
        selectedSchedule !== null &&
        selectedDays.length > 0,
    );
  }, [selectedBank, selectedAccount, selectedAccountBrokerage, selectedFund, selectedDays, selectedSchedule, amountValue]);

  useEffect(() => {
    if (localStorage.getItem('wizardRulesInvCompleted')) {
      setWizardCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      hasAuthPermission,
      hasCreateSubcriptionPermission,
      isAgreementRuleChecked,
      setIsAgreementRuleChecked,
      ruleBankData,
      setRuleBankData,
      rulesData,
      setRulesData,
      selectedBank,
      setSelectedBank,
      selectedAccount,
      setSelectedAccount,
      selectedAccountBrokerage,
      setSelectedAccountBrokerage,
      selectedFund,
      setSelectedFund,
      selectedSchedule,
      setSelectedSchedule,
      amountValue,
      setAmountValue,
      selectedDays,
      setSelectedDays,
      isFormWithValues,
      isValidFormData,
      resetSubscriptionRuleData,
      tabActive,
      setTabActive,
      countRuleSelected,
      setCountRuleSelected,
      enableEditForm,
      setEnableEditForm,
      showErrorScreen,
      setShowErrorScreen,
      selectedRulesToAuthorize,
      setRulesToAuthorize,
      investConfig,
      setInvestConfig,
      showAuthErrorScreen,
      setShowAuthErrorScreen,
      ruleFundData,
      setRuleFundData,
      profileRiskData,
      setProfileRiskData,
      wizardCompleted,
      setWizardCompleted,
    }),
    [
      hasAuthPermission,
      hasCreateSubcriptionPermission,
      isAgreementRuleChecked,
      setIsAgreementRuleChecked,
      ruleBankData,
      setRuleBankData,
      rulesData,
      setRulesData,
      selectedBank,
      setSelectedBank,
      selectedAccount,
      setSelectedAccount,
      selectedAccountBrokerage,
      setSelectedAccountBrokerage,
      selectedFund,
      setSelectedFund,
      selectedSchedule,
      setSelectedSchedule,
      amountValue,
      setAmountValue,
      selectedDays,
      setSelectedDays,
      isFormWithValues,
      isValidFormData,
      resetSubscriptionRuleData,
      tabActive,
      setTabActive,
      countRuleSelected,
      setCountRuleSelected,
      enableEditForm,
      setEnableEditForm,
      showErrorScreen,
      setShowErrorScreen,
      selectedRulesToAuthorize,
      setRulesToAuthorize,
      investConfig,
      setInvestConfig,
      showAuthErrorScreen,
      setShowAuthErrorScreen,
      ruleFundData,
      setRuleFundData,
      profileRiskData,
      setProfileRiskData,
      wizardCompleted,
      setWizardCompleted,
    ],
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
