import { pushAnalyticsEvent } from '@utils';
import { CSSProperties, useEffect } from 'react';

import customText from '../../lang/es.json';
import ErrorPage from '../ErrorPage/ErrorPage.component';

interface ErrorPageProps {
  titleStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
}

const pushAnalyticsRetry = () => {
  pushAnalyticsEvent({
    event: 'select_content',
    content_group: 'Error - Contenido no encontrado',
    content_type: 'Pantalla de error',
    item_id: 'CTA - Ir al inicio',
  });
};

function ErrorPageContent({ titleStyle, bodyStyle }: ErrorPageProps) {
  useEffect(() => {
    pushAnalyticsEvent({
      event: 'trackContentGroup',
      content_group: 'Error - Contenido no encontrado',
    });
  }, []);

  return (
    <ErrorPage
      onRedirect={pushAnalyticsRetry}
      bodyText={customText.errorPageContent.body}
      redirectTo='/'
      titleStyle={titleStyle}
      bodyStyle={bodyStyle}
    />
  );
}

export default ErrorPageContent;
