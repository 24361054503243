/* eslint-disable react/no-array-index-key */
import React from 'react';

export const renderTextWithBreaks = (text: string): JSX.Element[] => {
  const paragraphs = text.split('\n');
  return paragraphs.map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph}
      {index < paragraphs.length - 1 && <br />}
    </React.Fragment>
  ));
};

export const obfuscateAccount = (account: string) => {
  const { length } = account;

  if (length <= 4) {
    return account;
  }

  const obfuscatedDigits = '*'.repeat(length - 4);
  const lastFourDigits = account.substring(length - 4);
  return `${obfuscatedDigits}${lastFourDigits}`;
};
