/* eslint-disable import/no-unresolved */
import noResultsIcon from '@assets/noResults_moon.svg';
import { Box, Link } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import customText from '../../lang/es.json';

interface IErrorPage {
  titleText?: string;
  bodyText?: string;
  linkText?: string;
  redirectTo?: string;
  onRedirect?: () => void;
  titleStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
}

export function ErrorPermissionContent({ titleText, bodyText, linkText, redirectTo, onRedirect, titleStyle, bodyStyle }: IErrorPage) {
  return (
    <StyledErrorPage>
      <Box className='error-page'>
        <Box className='error-page__image' />

        <h4 style={titleStyle} className='error-page__title'>
          {titleText || customText.errorScreen.permission.title}
        </h4>

        <p style={bodyStyle} className='error-page__body'>
          {bodyText || customText.errorScreen.permission.description}
        </p>

        {/* If redirect is passed as param it is redirected onclick */}
        {redirectTo && (
          <Link data-testid='error-permission-link' onClick={onRedirect} as={RouterLink} className='error-page__action' to={redirectTo}>
            {linkText || customText.errorScreen.permission.link}
          </Link>
        )}
      </Box>
    </StyledErrorPage>
  );
}

export default ErrorPermissionContent;

const StyledErrorPage = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  flex-direction: column;

  .error-page {
    padding-top: 80px;

    &__image {
      background-image: url(${noResultsIcon});
      background-position: center;
      background-size: cover;
      width: 120px;
      height: 120px;
      margin: auto;
    }

    &__title {
      font-family: 'Red Hat Display', sans-serif;
      margin-top: 15px;
      margin-bottom: 0px;
      font-size: 2em;
      margin: 15px;
      font-weight: 700;
      line-height: '2rem';
    }

    &__body {
      margin: 16px;
      font-weight: 400;
    }

    &__action {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      text-decoration-line: underline;
      color: #2172dc;
      cursor: pointer;
    }
  }
`;
