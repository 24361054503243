/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { Box, Button, Checkbox, HStack, Menu, MenuButton, MenuList, useDisclosure, VStack } from '@chakra-ui/react';
import { IFrequencyDropdownHandle, IFrequencyProps } from '@interfaces';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ChevronDown, ChevronUp, X } from 'react-feather';

import customText from '../../lang/es.json';

export const FrequencyDropdown = forwardRef<IFrequencyDropdownHandle, IFrequencyProps>(
  ({ placeholder = 'Seleccione opción', inputTitle = '', data = [], isDisabled = false, onChange, selectedDays, setSelectedDays }, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [temporarySelectedDays, setTemporarySelectedDays] = useState<string[]>(selectedDays);
    const allDaysSelected = temporarySelectedDays.length === data.length;
    const [dynamicPlaceholder, setDynamicPlaceholder] = useState<string>(placeholder);
    const orderedDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'];

    const handleDayChange = (day: any) => {
      setTemporarySelectedDays((prev: any) => (prev.includes(day) ? prev.filter((d: any) => d !== day) : [...prev, day]));
    };

    const handleSelectAll = () => {
      if (allDaysSelected) {
        setTemporarySelectedDays([]);
      } else {
        setTemporarySelectedDays(data);
      }
    };

    const handleApply = () => {
      setSelectedDays([...temporarySelectedDays]);
      updatePlaceholder([...temporarySelectedDays]);
      onClose();
    };

    const updatePlaceholder = (days: string[]) => {
      let newPlaceholder = '';

      if (days.length === data.length) {
        newPlaceholder = customText.steps.createRule.frequencyDropdown.everyDay;
      } else if (days.length === 1) {
        newPlaceholder = `${customText.steps.createRule.frequencyDropdown.PlaceHolderInit} ${days[0].toLowerCase()}`;
      } else if (days.length > 1) {
        const sortedDays = [...temporarySelectedDays].sort((a, b) => orderedDays.indexOf(a) - orderedDays.indexOf(b));
        const formattedDays = sortedDays.join(', ').replace(/, ([^,]*)$/, ' y $1');
        newPlaceholder = `${customText.steps.createRule.frequencyDropdown.PlaceHolderInit} ${formattedDays.toLowerCase()}`;
      } else {
        newPlaceholder = placeholder;
      }

      if (newPlaceholder.length > 32) {
        newPlaceholder = `${newPlaceholder.substring(0, 32)}...`;
      }

      setDynamicPlaceholder(newPlaceholder);
    };

    useEffect(() => {
      if (isOpen) {
        setTemporarySelectedDays([...selectedDays]);
      }

      updatePlaceholder(selectedDays);
    }, [isOpen, selectedDays]);

    const handleMenuOpen = () => {
      setTemporarySelectedDays([...selectedDays]);
    };

    useImperativeHandle(ref, () => ({
      resetDays: () => {
        setSelectedDays([]);
        setTemporarySelectedDays([]);
        setDynamicPlaceholder(placeholder);
      },
    }));

    return (
      <VStack gap={0} alignItems='start' width='100%' zIndex={isOpen ? 1 : 0}>
        <Box fontSize='13px' fontFamily='Inter' color='#2F3034' opacity={isDisabled ? 0.4 : 1}>
          {inputTitle}
        </Box>
        <Menu matchWidth isOpen={isOpen} onClose={onClose} onOpen={handleMenuOpen}>
          <MenuButton
            as={Button}
            rightIcon={isOpen ? <ChevronUp height={30} width={30} /> : <ChevronDown height='30px' width='30px' />}
            onClick={isOpen ? onClose : onOpen}
            fontSize='14px'
            fontFamily='Inter'
            fontWeight={400}
            color='#44474F'
            height='36px'
            border='1px solid rgb(116, 119, 127)'
            textAlign='left'
            width='100%'
            marginTop='0px !important'
            sx={{
              bg: 'white',
              color: 'black',
              _hover: !isDisabled
                ? {
                    background: 'rgba(0, 0, 0, 0.06)',
                    border: '1px solid rgb(116, 119, 127)',
                    boxShadow: 'none',
                  }
                : {},

              _active: !isDisabled
                ? {
                    background: 'rgba(0, 0, 0, 0.16)',
                  }
                : {},
              _focusVisible: {
                background: 'rgba(0, 0, 0, 0.16)',
                boxShadow: 'none',
              },
              _disabled: {
                background: 'white',
                border: `1px solid #74777F`,
                boxShadow: 'none',
                opacity: 0.4,
                cursor: 'not-allowed',
              },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            isDisabled={isDisabled}
          >
            {dynamicPlaceholder}
          </MenuButton>
          <MenuList
            marginTop='-7px'
            marginBottom='-7px'
            position='relative'
            overflow='auto'
            boxShadow='0px 4px 6px -2px #131C2C0D, 0px 10px 15px -3px #131C2C1A'
            borderRadius='12px'
            background='white'
            padding={0}
            sx={{
              transition: 'transform 0.2ms fade-out !important',
              transform: isOpen ? 'scale(1)' : 'scale(1) !important',
              opacity: isOpen ? 1 : 0.9,
              transformOrigin: 'top !important',
            }}
            color='#2F3034'
            fontSize='14px'
            fontFamily='Inter'
          >
            <HStack position='sticky' bottom={0} bg='white' p='13px' borderBottom='1px solid #e2e8f0' width='100%' height='60px'>
              <Box width='100%' fontSize={14} fontWeight={700}>
                {customText.steps.createRule.frequencyDropdown.title}
              </Box>
              <X onClick={onClose} cursor='pointer' height={30} width={30} />
            </HStack>
            <VStack align='start' p={4} maxHeight='200px' overflowY='auto' paddingLeft='40px' paddingTop='15px' gap='10px'>
              <Checkbox isChecked={allDaysSelected} onChange={handleSelectAll}>
                <Box marginLeft='15px'>{customText.steps.createRule.frequencyDropdown.everyDay}</Box>
              </Checkbox>
              {data.map((day: any) => (
                <Checkbox key={day} isChecked={temporarySelectedDays.includes(day)} onChange={() => handleDayChange(day)}>
                  <Box marginLeft='15px'>{day}</Box>
                </Checkbox>
              ))}
            </VStack>
            <VStack position='sticky' bottom={0} bg='white' p='13px' borderTop='1px solid #e2e8f0' width='100%'>
              <Box bg='white' width='100%'>
                <Button width='100%' height='40px' colorScheme='blue' onClick={handleApply}>
                  {customText.steps.createRule.frequencyDropdown.ButtonApply}
                </Button>
              </Box>
            </VStack>
          </MenuList>
        </Menu>
      </VStack>
    );
  },
);
