/* eslint-disable react/destructuring-assignment */
import { Button, CloseButton, ModalProps, Text } from '@chakra-ui/react';
import { Modal, ModalBody, ModalChakra, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';

export interface IStateModalProps extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  headerState: 'error' | 'success' | 'warning' | 'info';
  headerText: string;
  isCancelButtonVisible: boolean;
  isContinueButtonVisible: boolean;
  isCloseButtonVisible?: boolean;
  cancelButtonText: string;
  continueButtonText: string;
  children: React.ReactNode;
  w?: string | number;
  h?: string | number;
}

export function StateModal(props: IStateModalProps) {
  return (
    <Modal
      data-testid='modal-wrapper'
      closeOnOverlayClick={false}
      blockScrollOnMount
      size={props.size}
      isCentered={props.isCentered}
      isOpen={props.isOpen}
      onClose={props.onClose}
      variant='states'
    >
      <ModalOverlay />
      <ModalContent width={props.w ? props.w : '400px'} height={props.h ? props.h : 'auto'}>
        {props.isCloseButtonVisible && (
          <CloseButton size='md' data-testid='modal-close-btn' onClick={props.onClose} sx={ModalChakra.close} />
        )}
        <ModalHeader state={props.headerState} data-testid='modal-header' pt='30px'>
          <Text textAlign='center' textStyle='h3' whiteSpace='break-spaces' sx={{ textWrap: 'auto' }} data-testid='modal-header-text'>
            {props.headerText}
          </Text>
        </ModalHeader>
        <ModalBody w='100%' gap='18px'>
          {props.children}
        </ModalBody>
        <ModalFooter>
          {props.isCancelButtonVisible && (
            <Button size='md' variant='primary-outline' onClick={props.onClose} data-testid='modal-cancel-btn'>
              {props.cancelButtonText}
            </Button>
          )}
          {props.isContinueButtonVisible && (
            <Button size='md' variant='primary' onClick={props.onContinue} data-testid='modal-continue-btn'>
              {props.continueButtonText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
