export const TEXT_PRESENTATION = {
  title: 'Conocé la nueva funcionalidad',
  description: 'En esta sección podrás crear reglas y visualizar la información de las automatizaciones.',
};

export const TEXT_STEP_ONE = {
  title: 'Crear regla',
  description: 'Desde este botón podés configurar las reglas para automatizar inversiones.',
};
export const TEXT_STEP_TWO = {
  title: 'Detalle de la regla',
  description: 'Acá encontrarás la información que cargaste de tus reglas automáticas y sus estados.',
};

export const TEXT_STEP_THREE = {
  title: 'Pausar reglas',
  description: 'Si tenés permisos de autorización, vas a poder pausar o activar reglas cuando lo necesites.',
};

export const TEXT_STEP_FOUR = {
  title: 'Autorizar reglas',
  description: 'Si tenés permisos de autorización, vas a poder autorizar las solicitudes por única vez desde esta sección.',
};

export const wizardSteps = [
  {
    title: TEXT_STEP_ONE.title,
    description: TEXT_STEP_ONE.description,
    target: 'button-create-rule',
    placement: 'right',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
  {
    title: TEXT_STEP_TWO.title,
    description: TEXT_STEP_TWO.description,
    target: 'rule-item-0',
    placement: 'top',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
  {
    title: TEXT_STEP_THREE.title,
    description: TEXT_STEP_THREE.description,
    target: 'action-rule-item-0',
    placement: 'right',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
  {
    title: TEXT_STEP_FOUR.title,
    description: TEXT_STEP_FOUR.description,
    target: 'tabs-rules--tab-1',
    placement: 'left',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
];

export const wizardStepsWIthStep0 = [
  {
    title: TEXT_PRESENTATION.title,
    description: TEXT_PRESENTATION.description,
    target: 'title-automation-rules-page',
    placement: 'top',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
  ...wizardSteps,
];

console.log(wizardStepsWIthStep0);
