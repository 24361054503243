/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import { frequencyOptions, scheduleOptions } from '@__mocks__';
import { Box, HStack, Input, Spinner, Stack, Tooltip, useToast, VStack } from '@chakra-ui/react';
import { ActionDropdown, FrequencyDropdown } from '@components';
import { useFetchFunds, useFetchProfileRisk } from '@hooks';
import { IOption, ISubscriptionForm } from '@interfaces';
import { accountsOptionFormat, defaultOptionFormat, fundsOptionFormat } from '@utils';
import { themeChakra } from '@wow/themes';
import { ToastComponent } from '@wow/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { HelpCircle } from 'react-feather';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import customText from '../../lang/es.json';
import { AppContext } from '../../providers/AppContextProvider';

export function SubscriptionRuleForm({ isDisabledForm = true }: ISubscriptionForm) {
  const {
    isValidFormData,
    selectedBank,
    setSelectedBank,
    selectedAccount,
    setSelectedAccount,
    selectedAccountBrokerage,
    setSelectedAccountBrokerage,
    selectedFund,
    setSelectedFund,
    selectedSchedule,
    setSelectedSchedule,
    amountValue,
    setAmountValue,
    selectedDays,
    setSelectedDays,
    enableEditForm,
    setEnableEditForm,
    ruleBankData,
    setRuleFundData,
    ruleFundData,
    profileRiskData,
    setProfileRiskData,
  } = useContext(AppContext) as any;

  const frequencyDropdownRef = useRef<any>(null);

  const [bankOptions, setBankOptions] = useState<IOption[]>([]);

  const [accountOptions, setAccountOptions] = useState<IOption[]>([]);
  const [isAccountDropdownDisabled, setIsAccountDropdownDisabled] = useState(true);

  const [accountBrokerageOptions, setAccountBrokerageOptions] = useState<IOption[]>([]);
  const [isAccountBrokerDropdownDisabled, setIsAccountBrokerDropdownDisabled] = useState(true);

  const [fundOptions, setFundOptions] = useState<IOption[]>([]);
  const [isFundDropdownDisabled, setIsFundDropdownDisabled] = useState(true);

  const [isAmountDropdownDisabled, setIsAmountDropdownDisabled] = useState(true);

  const [schedulerOptions, setSchedulerOptions] = useState<IOption[]>([]);
  const [isScheduleDropdownDisabled, setIsScheduleDropdownDisabled] = useState(true);

  const [isFrequencyDropdownDisabled, setIsFrequencyDropdownDisabled] = useState(true);

  const [hasBrokeragePlaceholderDefault, setHasBrokeragePlaceholderDefault] = useState(true);
  const [hasAccountPlaceholderDefault, setHasAccountPlaceholderDefault] = useState(true);
  const [hasFundPlaceholderDefault, setHasFundPlaceholderDefault] = useState(true);

  const { isFetchingFunds, fetchFundData } = useFetchFunds(setRuleFundData);
  const { isFetchingProfileRisk, fetchProfileRiskData } = useFetchProfileRisk(setProfileRiskData);

  const toast = useToast({
    containerStyle: {
      marginLeft: '60px',
    },
  });

  useEffect(() => {
    if (isFetchingFunds || isFetchingProfileRisk) {
      if (!toast.isActive('loading-toast')) {
        toast({
          id: 'loading-toast',
          position: 'bottom-left',
          status: 'info',
          isClosable: false,
          duration: null,
          render: (props: any) => (
            <ToastComponent
              {...props}
              isCtaLabel
              ctaLabel={
                <>
                  <Spinner w='16px' h='16px' color='#DAE2F9' opacity='0.5' />{' '}
                  <span style={{ color: '#DAE2F9', marginLeft: '5px', opacity: '0.5', fontFamily: 'Inter, sans-serif' }}>Cargando</span>
                </>
              }
              description='Estamos cargando la información.'
            />
          ),
        });
      }
    } else {
      toast.close('loading-toast');
    }
  }, [isFetchingFunds, isFetchingProfileRisk, toast]);

  useEffect(() => {
    if (ruleBankData) {
      setBankOptions(defaultOptionFormat(ruleBankData));
    }
  }, [ruleBankData]);

  useEffect(() => {
    if (ruleFundData) {
      if (ruleFundData.length === 0) {
        setIsFundDropdownDisabled(true);
        setHasFundPlaceholderDefault(false);
      } else {
        setIsFundDropdownDisabled(false);
        setHasFundPlaceholderDefault(true);
        setFundOptions(fundsOptionFormat(ruleFundData));
      }
    }
  }, [ruleFundData]);

  useEffect(() => {
    if (profileRiskData) {
      if (profileRiskData.accounts.length === 0 && profileRiskData.investors.length === 0) {
        setIsAccountDropdownDisabled(true);
        setIsAccountDropdownDisabled(true);
        setHasBrokeragePlaceholderDefault(false);
        setHasAccountPlaceholderDefault(false);
      } else {
        if (profileRiskData.investors.length > 0) {
          setAccountBrokerageOptions(
            accountsOptionFormat(profileRiskData, 'BROKERAGE', Number(selectedBank.value), Number(selectedFund.value)),
          );
        } else {
          setHasBrokeragePlaceholderDefault(false);
          setIsAccountBrokerDropdownDisabled(true);
        }

        if (profileRiskData.accounts.length > 0) {
          setAccountOptions(accountsOptionFormat(profileRiskData, 'ACCOUNTS', Number(selectedBank.value), Number(selectedFund.value)));
        } else {
          setHasAccountPlaceholderDefault(false);
          setIsAccountDropdownDisabled(true);
        }

        if (selectedBank.value === '016') {
          setIsAccountDropdownDisabled(true);
          if (profileRiskData.investors.length > 0) {
            setIsAccountBrokerDropdownDisabled(false);
          }
        } else {
          if (profileRiskData.accounts.length > 0) {
            setIsAccountDropdownDisabled(false);
          } else {
            setIsAccountDropdownDisabled(true);
          }

          setIsAccountBrokerDropdownDisabled(true);
        }
      }
    }
  }, [profileRiskData, selectedBank]);

  useEffect(() => {
    if (accountBrokerageOptions) {
      if (accountBrokerageOptions.length === 1) {
        setSelectedAccountBrokerage(accountBrokerageOptions[0]);
        if (accountOptions.length > 0) {
          setIsAccountDropdownDisabled(false);
        }
      } else if (accountOptions?.length === 1) {
        setIsAccountDropdownDisabled(false);
      }
    }

    if (accountOptions) {
      if (accountOptions.length === 1) {
        setSelectedAccount(accountOptions[0]);
        if (accountBrokerageOptions.length > 0) {
          setIsAccountBrokerDropdownDisabled(false);
        }
      } else if (accountBrokerageOptions?.length === 1) {
        setIsAccountBrokerDropdownDisabled(false);
      }
    }
  }, [accountBrokerageOptions, accountOptions]);

  useEffect(() => {
    if (selectedAccount) {
      setIsAmountDropdownDisabled(false);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (isValidFormData && enableEditForm) {
      setIsAmountDropdownDisabled(false);
      setIsFrequencyDropdownDisabled(false);
      setIsScheduleDropdownDisabled(false);
      setIsFundDropdownDisabled(false);
      setIsAccountDropdownDisabled(false);
      setIsAccountBrokerDropdownDisabled(false);

      setSchedulerOptions(filterScheduleOptions(selectedFund?.timeStart as any, selectedFund?.timeEnd as any, scheduleOptions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank, selectedAccount, selectedFund, isValidFormData, enableEditForm]);

  const handleResetSelectedDays = () => {
    setSelectedDays([]);
    if (frequencyDropdownRef.current) {
      frequencyDropdownRef.current.resetDays();
    }
  };

  const filterScheduleOptions = (timeStart: string, timeEnd: string, options: IOption[]): IOption[] => {
    const start = parseInt(timeStart?.replace(':', ''), 10);
    const end = parseInt(timeEnd?.replace(':', ''), 10);
    return options.filter(option => {
      const time = parseInt(option?.value?.replace(':', ''), 10);
      return time >= start && time <= end;
    });
  };

  const handleBankChange = (selectedBankOption: any) => {
    setEnableEditForm(false);

    if (selectedBank?.value !== selectedBankOption.value) {
      setSelectedBank(selectedBankOption);
      fetchFundData(Number(selectedBankOption.value));

      setSelectedFund(null);
      setIsFundDropdownDisabled(true);
      setFundOptions([]);

      setIsAmountDropdownDisabled(true);
      resetValue();

      setIsAccountDropdownDisabled(true);
      setSelectedAccount(null);
      setAccountOptions([]);

      setIsAccountBrokerDropdownDisabled(true);
      setSelectedAccountBrokerage(null);
      setAccountBrokerageOptions([]);
      setHasAccountPlaceholderDefault(true);
      setHasBrokeragePlaceholderDefault(true);
      setHasFundPlaceholderDefault(true);

      setIsFrequencyDropdownDisabled(true);

      setIsScheduleDropdownDisabled(true);
      setSelectedSchedule(null);
      handleResetSelectedDays();
      setProfileRiskData(null);
    }
  };

  const handleAccountChange = (selectedAccountOption: IOption) => {
    setEnableEditForm(false);

    if (selectedAccount?.value !== selectedAccountOption.value) {
      setSelectedAccount(selectedAccountOption);

      setIsAmountDropdownDisabled(false);

      if (profileRiskData.investors.length === 0) {
        setIsAccountBrokerDropdownDisabled(true);
      } else {
        setIsAccountBrokerDropdownDisabled(false);
      }

      resetValue();
    }
  };

  const handleAccountBrokerChange = (selectedAccountBrokerageOption: IOption) => {
    setEnableEditForm(false);

    if (selectedAccountBrokerage?.value !== selectedAccountBrokerageOption.value) {
      setSelectedAccountBrokerage(selectedAccountBrokerageOption);

      if (profileRiskData.accounts.length === 0) {
        setIsAccountDropdownDisabled(true);
      } else {
        setIsAccountDropdownDisabled(false);
      }
    }
  };

  const handleFundChange = (selectedFundOption: IOption) => {
    setEnableEditForm(false);

    if (selectedFund?.value !== selectedFundOption.value) {
      setSelectedFund(selectedFundOption);

      fetchProfileRiskData(Number(selectedBank.value), Number(selectedFundOption.value));

      setIsAccountDropdownDisabled(true);
      setSelectedAccount(null);
      setAccountOptions([]);

      setIsAccountBrokerDropdownDisabled(true);
      setSelectedAccountBrokerage(null);
      setAccountBrokerageOptions([]);
      setHasAccountPlaceholderDefault(true);
      setHasBrokeragePlaceholderDefault(true);

      setIsAmountDropdownDisabled(true);
      resetValue();

      setIsFrequencyDropdownDisabled(false);
      handleResetSelectedDays();

      setSchedulerOptions(filterScheduleOptions(selectedFundOption.timeStart as any, selectedFundOption.timeEnd as any, scheduleOptions));
      setIsScheduleDropdownDisabled(false);
      setSelectedSchedule(null);
    }
  };

  const handleScheduleChange = (selectedScheduleOption: IOption) => {
    setEnableEditForm(false);
    setSelectedSchedule(selectedScheduleOption);
  };

  const resetValue = () => {
    setAmountValue('');
  };

  const handleAMountValueChange = (values: NumberFormatValues) => {
    setEnableEditForm(false);
    const { value } = values;
    const parsedAmount = String(value ?? '');
    setAmountValue(parsedAmount);
  };

  const isAllowed = (values: NumberFormatValues) => {
    const { value } = values;
    const numericValue = value.replace(/\D/g, '');

    return numericValue.length <= 19 && parseInt(numericValue, 10) !== 0;
  };

  return (
    <HStack w='100%' gap={5} align='stretch' marginTop='30px' paddingRight={30}>
      <VStack w='100%' align='stretch' gap={30}>
        <Stack gap='10px' width='100%' alignItems='start'>
          <HStack gap='4px' justifyContent='center'>
            <Box fontSize='16px' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontWeight={700}>
              {customText.steps.createRule.form.bankTitle}
            </Box>
            <Tooltip hasArrow label={customText.steps.createRule.form.bankTooltip} bg='gray.300' placement='right'>
              <button>
                <HelpCircle width={20} height={20} color='#2172DC' />
              </button>
            </Tooltip>
          </HStack>
          <ActionDropdown
            type='bank'
            data-testId='subscription-select-bank'
            placeholder={customText.steps.createRule.form.bankPlaceholder}
            inputTitle={customText.steps.createRule.form.bankInput}
            data={bankOptions}
            isDisabled={isDisabledForm}
            onChange={handleBankChange}
            selectedOption={selectedBank}
          />
        </Stack>

        <Stack gap='10px' width='100%' alignItems='start'>
          <Box fontSize='16px' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontWeight={700}>
            {customText.steps.createRule.form.subsFromTitle}
          </Box>
          <ActionDropdown
            data-testId='subscription-select-account'
            placeholder={hasAccountPlaceholderDefault ? customText.steps.createRule.form.subsFromPlaceholder : '-'}
            inputTitle={customText.steps.createRule.form.subsFromInput}
            data={accountOptions}
            isDisabled={isAccountDropdownDisabled}
            onChange={handleAccountChange}
            selectedOption={selectedAccount}
          />
        </Stack>

        <Stack gap={0} width='100%' alignItems='start'>
          <HStack gap='4px' justifyContent='center' marginBottom='10px'>
            <Box fontSize='16px' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontWeight={700}>
              {customText.steps.createRule.form.subsAmountTitle}
            </Box>
            <Tooltip hasArrow label={customText.steps.createRule.form.subsAmountTooltip} bg='gray.300' placement='right'>
              <button>
                <HelpCircle width={20} height={20} color='#2172DC' />
              </button>
            </Tooltip>
          </HStack>
          <Box
            fontSize='13px'
            fontFamily={themeChakra.textStyles.titleMdBold.fontFamily}
            fontWeight={400}
            opacity={isAmountDropdownDisabled ? 0.4 : 1}
          >
            {customText.steps.createRule.form.subsAmountInput}
          </Box>
          <NumericFormat
            data-testId='subscription-input-amount'
            thousandSeparator='.'
            decimalSeparator=','
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            allowLeadingZeros={false}
            isAllowed={isAllowed}
            prefix='$ '
            onValueChange={handleAMountValueChange}
            customInput={Input}
            style={{
              marginTop: '0px',
              fontSize: '14px',
              fontFamily: themeChakra.textStyles.titleMdBold.fontFamily,
              color: 'black',
              borderColor: 'rgb(116, 119, 127)',
            }}
            placeholder={customText.steps.createRule.form.subsAmountPlaceholder}
            disabled={isAmountDropdownDisabled}
            value={amountValue}
            _placeholder={{ color: 'black' }}
            _focusVisible={{ color: 'none' }}
          />
        </Stack>
      </VStack>
      <VStack w='100%' align='stretch' gap={30}>
        <Stack gap='10px' width='100%' alignItems='start'>
          <Box fontSize='16px' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontWeight={700}>
            {customText.steps.createRule.form.subsFundsTitle}
          </Box>

          <ActionDropdown
            type='fund'
            data-testId='suscription-select-fund'
            placeholder={hasFundPlaceholderDefault ? customText.steps.createRule.form.subsFundsPlaceholder : '-'}
            inputTitle={customText.steps.createRule.form.subsFundsInput}
            data={fundOptions}
            isDisabled={isFundDropdownDisabled}
            onChange={handleFundChange}
            selectedOption={selectedFund}
          />
        </Stack>

        <Stack gap='10px' width='100%' alignItems='start'>
          <Box fontSize='16px' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontWeight={700}>
            {customText.steps.createRule.form.accountTitle}
          </Box>
          <ActionDropdown
            data-testId='subscription-select-brokerage'
            placeholder={hasBrokeragePlaceholderDefault ? customText.steps.createRule.form.accountPlaceholder : '-'}
            inputTitle={customText.steps.createRule.form.accountInput}
            data={accountBrokerageOptions}
            isDisabled={isAccountBrokerDropdownDisabled}
            onChange={handleAccountBrokerChange}
            selectedOption={selectedAccountBrokerage}
          />
        </Stack>

        <Stack gap='10px' width='100%' alignItems='start'>
          <Box fontSize='16px' fontFamily={themeChakra.textStyles.titleMdBold.fontFamily} fontWeight={700}>
            {customText.steps.createRule.form.frequencyTitle}
          </Box>
          <HStack gap='16px' width='100%' alignItems='start'>
            <Stack gap='10px' width='100%' alignItems='start'>
              <FrequencyDropdown
                data-testId='suscription-select-frequency'
                placeholder={customText.steps.createRule.form.frequencyPlaceholder}
                inputTitle={customText.steps.createRule.form.frequencyInput}
                data={frequencyOptions}
                isDisabled={isFrequencyDropdownDisabled}
                selectedDays={selectedDays}
                setSelectedDays={(days: string[]) => setSelectedDays(days)}
                ref={frequencyDropdownRef}
              />
            </Stack>

            <Stack gap='10px' width='100%' alignItems='start'>
              <ActionDropdown
                data-testId='suscription-select-time'
                placeholder={customText.steps.createRule.form.frequencyPlaceholderSC}
                inputTitle={customText.steps.createRule.form.frequencyInputSC}
                data={schedulerOptions}
                isDisabled={isScheduleDropdownDisabled}
                onChange={handleScheduleChange}
                selectedOption={selectedSchedule}
              />
            </Stack>
          </HStack>
        </Stack>
      </VStack>
    </HStack>
  );
}
