/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-vars */

import customText from '../lang/es.json';

export const getBadgeStatus = data => {
  const status = data?.status?.toLowerCase();
  const actions = data?.payload?.operatorsBasedInActions;

  if (status?.toLowerCase() === 'inactive') {
    if (data?.payload?.authorizationStatus?.toLowerCase() === 'pending') {
      return 'Creada';
    }
    return 'Pausada';
  }
  return 'Activa';
};

export const formatBankCode = code => {
  return code?.toString().padStart(3, '0');
};

export const formatHour = hour => {
  return `${hour?.toString().padStart(2, '0')}:00 h`;
};

export const formatSchedule = days => {
  const orderedDays = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes'];
  const sortedDays = days?.sort((a, b) => a - b);
  const dayNames = sortedDays.map(day => orderedDays[day - 1]);

  let frequencyLabel = '';

  if (days.length === 5) {
    frequencyLabel = ' Todos los días';
  } else if (days.length === 1) {
    frequencyLabel = ` Todos los ${dayNames[0]}`;
  } else if (days.length > 1) {
    const lastDay = dayNames.pop();
    frequencyLabel = `Todos los ${dayNames.join(', ')} y ${lastDay}`;
  }

  return frequencyLabel;
};

export const formatAccountType = type => {
  return type.toLowerCase() === 'cuenta corriente' ? 'CC' : 'CA';
};

export const formatRemainingAuthorizations = items => {
  const authorizerCount = items?.payload?.operatorsBasedInActions?.filter(item => item.action === 'AUTHORIZE')?.length;
  const remainingAuthorizers = items?.payload?.totalAuthorizers - authorizerCount;

  if (items?.payload?.authorizationStatus === 'PENDING') {
    if (remainingAuthorizers === 1) {
      return `${customText.ruleCard.labelLeftOne} ${remainingAuthorizers} ${customText.ruleCard.labelLeftAuthorizer}`;
    }
    return `${customText.ruleCard.labelLeftMore} ${remainingAuthorizers} ${customText.ruleCard.labelLeftAuthorizers}`;
  }
  return '';
};

export const sortItemsByCriteria = data => {
  return data?.items
    .map(item => {
      const { status, authorizationStatus, operatorsBasedInActions } = item.payload;

      let groupPriority: number;
      if (status?.toLowerCase() === 'active') {
        groupPriority = 1; // activa
      } else if (status?.toLowerCase() === 'inactive' && authorizationStatus === 'PENDING' && operatorsBasedInActions.length === 1) {
        groupPriority = 2; // creada
      } else if (status?.toLowerCase() === 'inactive' && authorizationStatus === 'AUTHORIZED') {
        groupPriority = 3; // pausada
      } else {
        groupPriority = 4;
      }

      return { ...item, groupPriority };
    })
    .sort((a, b) => {
      if (a.groupPriority !== b.groupPriority) {
        return a.groupPriority - b.groupPriority;
      }
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    });
};
