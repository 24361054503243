/* eslint-disable import/prefer-default-export */
export const permissionDictionary = {
  CreateSubscription: 'f83a3ef59dd5ba8c587bc960f94a1ad7fc28e88988a46905d724372d5c4c5754',
  authorizations: 'c0feccee508d7fe2c4ea913eff314db85ee211893bb3f43ac0d7d6d97b790b24',
};

type HasPermissionFunction = (permissionToCheck: string[], permissionList?: string[]) => boolean;

export const getArrayStorage = (arrayName: string) => {
  const item = sessionStorage.getItem(arrayName);
  if (item) {
    try {
      const value = JSON.parse(item);
      if (Array.isArray(value)) return value;
    } catch {
      return [];
    }
  }
  return [];
};

export const setArrayStorage = (arrayName: string, value: string[]) => {
  sessionStorage.setItem(arrayName, JSON.stringify(value));
};

export const clearArrayStorage = (arrayName: string) => {
  setArrayStorage(arrayName, []);
};

export const addArrayStorageItem = (arrayName: string, item: string) => {
  const array = getArrayStorage(arrayName);
  array.push(item);
  setArrayStorage(arrayName, array);
};

export const removeArrayStorageItem = (arrayName: string, item: string) => {
  const array = getArrayStorage(arrayName);
  const newArray = array.filter(element => element !== item);
  setArrayStorage(arrayName, newArray);
};

export const PERMISSIONS = 'permissions';

// Store all the permissions
export const setPermissions: (permissions: string[]) => void = permissions => {
  setArrayStorage(PERMISSIONS, permissions);
};

// Get all the permissions
export const getPermissions: () => string[] = () => getArrayStorage(PERMISSIONS);

// Check if has all the permissions
// Allow an string of comma separated permissions to check
export const hasAllPermission: HasPermissionFunction = (permissionToCheck, permissionList = getPermissions()) =>
  permissionToCheck.every(permission => {
    const splittedPermission = permission.replace(' ', '').split(',');
    if (splittedPermission.length > 1) {
      return hasAllPermission(splittedPermission, permissionList);
    }
    return permissionList.includes(splittedPermission[0]);
  });

// Check if has one of the the permissions
// Allow an string of comma separated permissions to check
export const hasSomePermission: HasPermissionFunction = (permissionToCheck, permissionList = getPermissions()) =>
  permissionToCheck.some(permission => hasAllPermission(permission.replace(' ', '').split(','), permissionList));
