// Array representing days starting from Sunday (0)
const WEEKDAY_NAMES = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];

/**
 * Formats an array of numeric weekdays into a human-readable Spanish string.
 *
 * Given an array of numbers representing weekdays (0 = domingo, 1 = lunes, ..., 6 = sábado),
 * this function will return a string with the corresponding weekday names in Spanish.
 * It inserts commas between days when there are more than two, and uses 'y' before the last day.
 *
 * @param {number[]} weekdays - An array of numbers representing the weekdays (0-6).
 * @returns {string} A formatted string of the weekdays in Spanish.
 *                   Examples:
 *                   - [1, 2] => "lunes y martes"
 *                   - [1, 2, 3] => "lunes, martes y miércoles"
 *                   - [1] => "lunes"
 */
export function formatWeekdays(weekdays: number[]): string {
  const dayNames = weekdays.map(dayNumber => WEEKDAY_NAMES[dayNumber]);

  if (dayNames.length === 1) {
    return dayNames[0];
  }

  const lastDay = dayNames.pop();
  return `${dayNames.join(', ')} y ${lastDay}`;
}

/**
 * Formats an hour (0-23) into a time string with "HH:00 h" format.
 *
 * This function takes a numeric hour and returns a string formatted as "HH:00 h".
 * If the hour is less than 10, it will pad the hour with a leading zero.
 *
 * @param {number} hour - The hour of the day (0-23).
 * @returns {string} A string representing the formatted hour,
 *                   e.g., 14 => "14:00 h", 9 => "09:00 h".
 */
export function formatRuleHour(hour: number): string {
  const hourStr = String(hour).padStart(2, '0');
  return `${hourStr}:00 h`;
}
